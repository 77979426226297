@import '~styles/var';
@import '~styles/mixin';

.Cervitem {
  height: 100%;
}

.Item-Container {
  padding: 0;
  margin: 0 auto;
  max-width: 1020px;
}
.search-container {
  // transform: scale(1.5);
  // width: 100%;
  // margin: 10px 20px 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  .input-group {
    width: 200px;
  }
}
.CerviTemProductList {
  min-width: 1020px;
  min-height: 950px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  padding: 0;
}
@media (max-width: 1149px) {
  .CerviTemProductList {
    min-width: 500px;
    justify-content: center;
  }
}

.CerviTemProductList.none {
  justify-content: center;
  padding-top: 20px;
}
.CerviTemProduct:hover {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.4);
}
.CerviTemProduct {
  transition: box-shadow 0.3s;
  flex: none;
  list-style: none;
  margin: 10px 10px 10px 10px;
  background-color: $gray-100;
  // max-height: 300px;
  width: $picture-preview-big-width;
  cursor: pointer;

  .card-img {
    width: $picture-cervitem-thumb-width - 2;
    height: $picture-cervitem-thumb-height;
    position: relative;
    div {
      background-size: contain;
      background-color: white;
      // border-bottom: 1px solid #ddd;
    }
  }
  .cervitem-info {
    display: block;
    .cervitem-category {
      margin: 0;
      color: $gray-500;
    }
    .cervitem-name {
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-all;
    }
    .cervitem-price {
      font-size: 20px;
      font-weight: 500;
      text-align: right;
      margin-right: 10px;
      color: #ae0000;
    }
  }
  .card-footer {
    display: flex;
    justify-content: space-around;
  }
}
