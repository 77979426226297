@import '~styles/var';
@import 'node_modules/bootstrap/scss/bootstrap';
@import '~styles/common';

.CheckWindowWidth {
  position: fixed;
  top: $header-height;
  right: 0;
  width: 100vw;
  height: calc(100vh - #{$header-height});
  z-index: 9;
  animation: opacity $nav-transition-time;
  background: rgba(0, 0, 0, 0.83);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: $nav-width;
  color: $white;
  font-size: 3rem;
}

.display-none {
  display: none;
}
