@charset "UTF-8";
/*bootstrap*/
/*변수*/
/*z-index*/
:root {
  --scrollbar-width: calc(100vw - 100%); }

.LanguageModal .modal-header {
  padding: 1rem 2rem; }
  .LanguageModal .modal-header .modal-title {
    font-size: 25px; }
  .LanguageModal .modal-header .close {
    font-size: 35px; }

.LanguageModal .modal-body {
  padding: 2rem 2rem; }
  .LanguageModal .modal-body select {
    height: 40px;
    font-size: large; }
