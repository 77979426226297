@import 'var';

* {
  -webkit-user-select: none;
  user-select: none;
}
input,
textarea {
  -webkit-user-select: none;
  user-select: text;
}

.btn svg {
  height: 1.1em;
  width: 1.1em;
}

svg {
  height: 1.1em;
  width: 1.1em;
  vertical-align: -0.2em !important;
}

.card {
  .card-body {
    padding: 0.5rem 1rem;
    min-height: 1px; //ie 버그
  }
  .card-header {
    padding: 0.5rem 1rem;
    font-weight: bold;
  }
  .card-toolbar {
    padding: 0.3rem 0.5rem;
    & > * {
      margin: 2px 5px 2px 0;
    }
  }
  .card-body {
    padding: 0.5rem;
    &.np {
      padding: 0;
    }
    & > form {
      margin-bottom: -5px;
    }
  }
}

.card-mask {
  z-index: 500;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba($gray-300, 0.3);
  .lds-roller div:after {
    background: $pink;
  }
}

.container-fluid {
  & > * {
    margin-bottom: 10px;
  }
}

.h-flex {
  display: flex;
  flex-direction: column;
  height: 100%;
  //min-height: 100%;

  & > * {
    flex-shrink: 0;
  }
  .basis-300 {
    flex-grow: 1;
    //flex-basis: 300px;

    .card-body {
      height: 250px;
      display: flex;
      flex-direction: column;
    }
  }
}

/*폼관련*/
.field-number {
  width: 6rem !important;
  text-align: right;
}

.btn > .button-file {
  left: 0;
  top: 0;
  //right: 0;
  bottom: 0;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 2rem;
  margin: 0;
  opacity: 0;
}

.custom-checkbox {
  -webkit-user-select: none;
  user-select: none;
}

.button-box {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  & > * {
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.date-btns {
  button {
    border: 1px solid #adb5bd;
  }
}

.ws-pre-line {
  white-space: pre-line;
}

.input-group {
  display: flex !important;
}

.form-group {
  margin-bottom: 0.5rem;
}

form.search {
  .form-row {
    margin-left: 0;
    margin-right: 0;
  }
  .form-group {
    flex: 0 0 auto;
    width: auto;
    padding-left: 0;
    padding-right: 0;
    margin-right: 1rem;

    display: flex;
    align-items: center;

    &.ml-auto {
      margin-right: 0;
    }

    label {
      min-width: 90px;
      text-align: right;
      &.custom-control-label {
        min-width: initial;
        text-align: left;
      }
      //padding: 0 1rem;
      margin: 0 1rem;
      white-space: nowrap;
    }
  }
}

#printJS {
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 1px;
}

.lds-ellipsis.small {
  transform: scale(0.5);
}

.Toastify__toast--warning {
  background: $warning;
  color: black;
}

.close-tip {
  min-width: 280px !important;
}

.status-0 {
  color: $gray-600;
}
.status-1 {
  color: $yellow;
}
.status-2,
.status-3,
.status-4,
.status-5,
.status-6,
.status-2-6 {
  color: $green;
}
.status-7 {
  color: $blue;
}
.status-8 {
  color: $pink;
}
.status-9 {
  color: $indigo;
}

@include media-breakpoint-down(xs) {
  form.search {
    .form-row {
      display: block;
      .form-group {
        margin-right: 0;
        label {
          margin-left: 0;
        }
      }
    }
  }
}

span.req {
  color: $danger;
}

.border-3 {
  border-width: 3px !important;
}

.border-blue {
  border-color: #167495 !important;
}
.border-green {
  border-color: green !important;
}
.border-red {
  border-color: red !important;
}
.text-blue {
  color: #167495 !important;
}
.text-green {
  color: green !important;
}
.text-red {
  color: red !important;
}

.btn-purple {
  @include button-variant($purple, $purple);
}

.btn-cyan {
  @include button-variant($cyan, $cyan);
}

.btn-teal {
  @include button-variant($teal, $teal);
}

.btn-skyBlue {
  @include button-variant($skyBlue, $skyBlue);
}

.btn-sunsetOrange {
  @include button-variant($sunsetOrange, $sunsetOrange);
}

.btn-crimsonRed {
  @include button-variant($crimsonRed, $crimsonRed);
}

.btn-tealGreen {
  @include button-variant($tealGreen, $tealGreen);
}

.btn-darkgray {
  @include button-variant($darkgray, $darkgray);
}

.badge-coral {
  color: white !important;
  @include badge-variant($coral);
}

.badge-crimsonRed {
  color: white !important;
  @include badge-variant($crimsonRed);
}

.badge-gray600 {
  color: white !important;
  @include badge-variant($gray-600);
}

.bg-coral {
  background-color: $coral !important;
}

.bg-crimsonRed {
  background-color: $crimsonRed !important;
}

.bg-gray600 {
  background-color: $gray-400 !important;
}

.ai-result-badge {
  width: 55px !important;
}

.text-coral {
  color: $coral !important;
}

.text-crimsonRed {
  color: $crimsonRed !important;
}
