@import '~styles/var';

.PopupModal {
  .modal-body {
    padding: 0;
  }
  &.loaded img {
    max-width: 100%;
  }
}
