@charset "UTF-8";
/*bootstrap*/
/*변수*/
/*z-index*/
:root {
  --scrollbar-width: calc(100vw - 100%); }

.CervitemProduct {
  width: 100%;
  height: 100%; }
  .CervitemProduct .Cervitem-Info {
    height: 560px; }
  .CervitemProduct .prod-item-img-carousel {
    height: 100%; }
    .CervitemProduct .prod-item-img-carousel .carousel-inner {
      height: 100%; }
  .CervitemProduct .prod-item {
    height: 100%; }
    div .CervitemProduct .prod-item div {
      background-color: #fff; }
  .CervitemProduct .prod-by-btn-table-container .prod-quantity-container {
    display: flex;
    width: 100px;
    border: 1px solid #ccc; }
    .CervitemProduct .prod-by-btn-table-container .prod-quantity-container input {
      width: 100%;
      text-align: center;
      font-size: 15px;
      border: 0;
      border-right: 1px solid #ccc; }
    .CervitemProduct .prod-by-btn-table-container .prod-quantity-container button {
      border: 0;
      background-color: #fff; }
    .CervitemProduct .prod-by-btn-table-container .prod-quantity-container .prod-quantity-minus {
      border-right: 1px solid #ccc; }
  .CervitemProduct .prod-by-btn-container {
    display: flex;
    justify-content: space-around; }
  .CervitemProduct .prod-totalprice {
    font-size: 20px;
    font-weight: 500;
    text-align: right;
    margin-right: 10px;
    color: #ae0000; }
  .CervitemProduct .prod-detail-img-container .prod-detail-img {
    padding: 10px;
    width: 100%;
    object-fit: contain; }
