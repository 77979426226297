@import '~styles/var';

.PatInfoForAndroid {
  .modal-header {
    padding: 1rem 2rem;

    .modal-title {
      font-size: 25px;
    }

    .close {
      font-size: 35px;
    }
  }

  .modal-body {
    padding: 1rem 2rem;

    .row {
      margin-bottom: 10px;
      &.end {
        margin-bottom: 0;
      }

      .form-label {
        display: flex;
        align-content: center;

        .form-control {
          width: 60%;
        }

        label {
          font-size: 20px;
          margin-right: 40px;
        }

        .custom-checkbox .custom-control-label::before,
        .custom-checkbox .custom-control-label::after {
          top: 7px;
          width: 16px;
          height: 16px;
        }

        .custom-radio .custom-control-label::before,
        .custom-radio .custom-control-label::after {
          top: 7px;
          width: 16px;
          height: 16px;
        }

        &.col-md-6 .form-group {
          display: flex;
          width: 100%;
        }

        &.col-md-12 .form-group {
          width: 100%;
          display: flex;

          .form-control {
            width: 65%;
          }
        }
      }
    }
  }

  .modal-footer {
    padding: 1rem 2rem;

    .btn {
      font-size: 23px;
    }
  }
}
