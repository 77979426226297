@import '~styles/var';

.DataGrid {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%; //for y scroll

  .area-paging {
    flex: none;
    display: flex;
    height: 32px;
    background-color: #f0f3f5;

    border-color: $card-border-color;
    border-style: solid;
    border-width: 1px 0 0 0;

    align-items: center;
    padding: 0 10px;
    //justify-content: center;
    color: $gray-700;
    font-weight: bold;

    /*     & > * {
      //margin-right: 5px !important;
      &:last-child {
        margin-right: 0 !important;
      }
    } */

    button {
      background-color: transparent;
      overflow: hidden;
      appearance: none;
      border: 0;
      padding: 0;
      width: 20px;
      height: 20px;
      color: $gray-700;
      cursor: pointer;
      &:disabled {
        color: $gray-400;
      }
    }

    .badge {
      vertical-align: 0.05em;
    }
  }

  .ag-theme-balham {
    height: 100%; //for y scroll
    overflow: hidden;

    .ag-root {
      border-width: 0;
    }
    .ag-root,
    .ag-floating-top {
      border-color: $card-border-color;
    }
    .ag-header {
      background-color: #f0f3f5;
    }
    .ag-row {
      border-color: $gray-400;
    }

    .ag-header-cell,
    .ag-cell {
      padding-left: 6px;
      padding-right: 6px;
    }
    .ag-cell.ag-cell-inline-editing {
      padding: 0;
    }
    .ag-cell.button {
      .ag-react-container {
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: center;
        .btn {
          height: 20px;
          line-height: 0.5;
        }
      }
    }
  }

  &.border-grid {
    .ag-root {
      border-width: 1px;
    }
    .area-paging {
      border-width: 0 1px 1px 1px;
    }
  }
}
