@charset "UTF-8";
/*bootstrap*/
/*변수*/
/*z-index*/
:root {
  --scrollbar-width: calc(100vw - 100%); }

.Cervitem {
  height: 100%; }

.Item-Container {
  padding: 0;
  margin: 0 auto;
  max-width: 1020px; }

.search-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end; }
  .search-container .input-group {
    width: 200px; }

.CerviTemProductList {
  min-width: 1020px;
  min-height: 950px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  padding: 0; }

@media (max-width: 1149px) {
  .CerviTemProductList {
    min-width: 500px;
    justify-content: center; } }

.CerviTemProductList.none {
  justify-content: center;
  padding-top: 20px; }

.CerviTemProduct:hover {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.4); }

.CerviTemProduct {
  transition: box-shadow 0.3s;
  flex: none;
  list-style: none;
  margin: 10px 10px 10px 10px;
  background-color: #f8f9fa;
  width: 320px;
  cursor: pointer; }
  .CerviTemProduct .card-img {
    width: 318px;
    height: 320px;
    position: relative; }
    .CerviTemProduct .card-img div {
      background-size: contain;
      background-color: white; }
  .CerviTemProduct .cervitem-info {
    display: block; }
    .CerviTemProduct .cervitem-info .cervitem-category {
      margin: 0;
      color: #adb5bd; }
    .CerviTemProduct .cervitem-info .cervitem-name {
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-all; }
    .CerviTemProduct .cervitem-info .cervitem-price {
      font-size: 20px;
      font-weight: 500;
      text-align: right;
      margin-right: 10px;
      color: #ae0000; }
  .CerviTemProduct .card-footer {
    display: flex;
    justify-content: space-around; }
