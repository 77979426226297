@import '~styles/var';

header {
  display: flex !important;
  align-items: center;
  border-bottom: 1px solid $card-border-color;

  & > * {
    display: block;
    flex: none;
  }

  .logo {
    //height: $header-height;
    width: $nav-width - 0.6rem;
    margin-left: 0.3rem;
  }

  .open-button {
    font-size: 2.3rem;
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0 0 0 0.3em;
    cursor: pointer;
  }

  .user-info {
    display: flex;
    align-items: center;
    flex-direction: row;

    list-style: none;
    margin: 0;
    margin-left: auto;
    align-content: center;
    li {
      padding: 0 5px;
    }

    //caret 삭제
    .dropleft .dropdown-toggle::before {
      display: none;
    }
  }
}
