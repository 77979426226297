@import '~styles/var';

.CervitemCart {
  .table {
    th {
      text-align: center;
    }
    td {
      vertical-align: middle;
    }
  }
  .Cart-Container {
    // height: calc(100vh - #{$header-height} - 53px);
    max-width: 1020px;
    width: 100%;
  }
  .cart-orderbtn-container {
    width: 100%;
    border-top: 1px solid black;
  }
  .empty-list {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
  }
  // ul {
  //   padding: 0 0 40px 0;
  //   margin: 0 0 20px 0;
  //   border-bottom: 2px solid black;
  // }
  .input-box {
    display: flex;
    align-items: center;
  }
  .cart-item {
    .item-info-box {
      margin-left: 40px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .item-picture {
      width: 100px;
      height: 100px;
    }
  }
  .item-delete-btn {
    display: flex;
    justify-content: center;
  }

  .item-quantity-container {
    display: flex;
    width: 80px;
    border: 1px solid #ccc;
    margin: 0;
    transform: scale(0.9);
    input {
      width: 100%;
      text-align: center;
      border: 0;
      border-right: 1px solid #ccc;
    }
    .item-quantity-btn-container {
      button {
        width: 100%;
        height: 50%;
        border: 0;
        background-color: #fff;
      }
      .item-quantity-plus {
        border-bottom: 1px solid #ccc;
      }
    }
  }

  .item-price {
    font-size: 15px;
  }

  input[type='checkbox'] {
    cursor: pointer;
    zoom: 1.3;
  }
}
