@import '~styles/var';

.Video {
  .card-body {
    min-height: 200px !important;
  }
  div.none {
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray-500;
  }

  ul {
    list-style: none;
    padding-inline-start: 0;
    margin-bottom: 0;
    li {
      padding: 10px;
      border-bottom: 1px solid $gray-300;
      &:last-child {
        border-bottom: 0px;
      }

      .row {
        cursor: pointer;

        .title {
          font-size: 1.6em;
          font-weight: bold;
          &.read {
            .unread {
              display: none;
            }
          }
        }

        .synopsis {
          span {
            display: block;
            &.summary {
              font-size: 1.3em;
            }
          }
        }

        .thumbnail {
          align-items: center;

          img {
            width: 100%;
          }

          button {
            margin-top: 10px;
            width: 100%;
          }
        }
      }
    }
  }
}

.PopupModal.video {
  .modal-title {
    font-weight: bold;
  }
}

.video-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    padding-top: 56.25%;
    display: block;
    content: '';
  }

  iframe,
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  img.thumb {
    transition: transform 0.1s;
    &:hover {
      transform: scale(1.05);
    }
  }
}

@include media-breakpoint-up(xs) {
  .modal-dialog.PopupModal.video {
    max-width: 660px;
    margin: 1.75rem auto;
  }
}
