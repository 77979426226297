@import '~styles/var';
@import '~styles/mixin';

.accordion-group {
  overflow: hidden;
  margin: 0;
  padding: 0;
  list-style: none;
  width: calc(100vw - #{$nav-width});
  height: calc(100vh - #{$header-height} - 20px);
  display: flex;
  align-items: center;
  transition: all 800ms ease;

  & li {
    cursor: pointer;
    position: relative;
    display: flex;
    overflow: hidden;
    margin: 0;
    padding: 2em;
    list-style: none;
    width: 16.66666667%;
    height: inherit;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 800ms ease-in-out;
    &.first {
      background-image: url('../../resources/cervitem/1.jpg');
    }
    &.second {
      background-image: url('../../resources/cervitem/2.jpg');
    }
    &.third {
      background-image: url('../../resources/cervitem/3.jpg');
    }
    &.fourth {
      background-image: url('../../resources/cervitem/4.jpeg');
    }

    &:hover,
    &.out {
      width: 50% !important;
      & .accordion-overlay {
        &.first {
          opacity: 0;
        }
        &.second {
          opacity: 0;
        }
        &.third {
          opacity: 0;
        }
        &.fourth {
          opacity: 0;
        }
      }

      & h2 {
        color: $nav-font;
      }
    }

    &:not(:hover) {
      width: 16.66666667%;
    }

    & .accordion-overlay {
      &.first {
        background-color: $nav-first;
        opacity: 0.8;
      }
      &.second {
        background-color: $nav-second;
        opacity: 0.8;
      }
      &.third {
        background-color: $nav-third;
        opacity: 0.8;
      }
      &.fourth {
        background-color: $nav-fourth;
        opacity: 0.8;
      }
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      transition: all 800ms ease-in-out;
    }

    & h2 {
      position: fixed;
      color: $white;
      font-family: 'Noto Sans KR', Arial, sans-serif;
      letter-spacing: 0.15rem;
      padding: 0;
      top: 75px;
    }
  }
}
