@import '~styles/var';
@import '~styles/mixin';

.CerviAiResultWindowForAndroid {
  @include window-layout;

  top: 0;
  left: 0;

  .highlight {
    padding: 2px 5px;
    color: white;
    background-color: #f86c6b;
  }

  .area-back-button {
    z-index: 960;
    .btn {
      width: 50px;
      height: 50px;

      svg {
        width: 25px;
        height: 25px;
      }
    }
  }

  background-color: #212529;
  display: flex;

  .picture-container {
    display: flex;
    flex-direction: column;
    flex: 1 1;
  }
  .area-picture {
    flex: 5 1 auto;
    position: relative;
    cursor: pointer;
    // height: 600px;
  }

  .area-info {
    background-color: $gray-800;
    flex: 0 0 400px;

    overflow-y: auto;

    & > * {
      padding: 5px !important;
    }

    @media (max-width: 768px) {
      flex: 0 0 45% !important;
    }
  }

  .area-picture-list {
    margin: 0;
    padding: 0;
    display: flex;
    align-content: flex-start;
    justify-content: center;
    background-color: #212529;
    flex: 1 1;

    .workspace {
      border: 2px solid gray;

      &.active {
        border-color: $green;
      }
    }
    // height: $picture-preview-height;
    .picture {
      // border: 2px solid gray;
      // flex: 1 0 auto;
      // width: 25%;
      margin: 0 10px;
      position: relative;
      // display: flex;
      // justify-content: center;
      // height: inherit;

      &.active {
        border-color: $green;
      }

      .represent {
        position: absolute;
        width: 17px;
        height: 17px;
        top: 0;
        right: 0;

        .icon {
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
      }

      .ai-result {
        position: absolute;
        top: 0;
        left: 0;

        .badge {
          opacity: 0.85;

          // width: 100px;
          height: 30px;
          font-size: 18px;

          margin-bottom: 3px;
        }

        .probability {
          width: 60px;
        }
      }
    }
  }

  .classResult-grid {
    text-align: center;
    font-size: 20px;

    .bg-danger-half {
      border-color: transparent !important;
      background-image: linear-gradient(90deg, #f86c6b 50%, #f8f9fa 0) !important;
      background-origin: border-box !important;
    }

    .bg-normal {
      border-color: transparent !important;
      background-image: linear-gradient(90deg, #20a8d8 25%, #f8f9fa 0) !important;
      background-origin: border-box !important;
    }
    .bg-atypical {
      border-color: transparent !important;
      background-image: linear-gradient(90deg, #f8f9fa 25%, #4dbd74 25% 50%, #f8f9fa 0) !important;
      background-origin: border-box !important;
    }
    .bg-positive1 {
      border-color: transparent !important;
      background-image: linear-gradient(90deg, #f8f9fa 50%, $coral 50% 75%, #f8f9fa 0) !important;
      background-origin: border-box !important;
    }
    .bg-positive2 {
      border-color: transparent !important;
      background-image: linear-gradient(90deg, #f8f9fa 75%, $crimsonRed 0) !important;
      background-origin: border-box !important;
    }
  }

  .classResult-TD {
    text-align: center;
    color: $gray-800;
    margin: 20px 0 20px 0;
  }

  .graph-container {
    color: white;
    margin: 5px 0 5px 0;
  }
  .descr-container {
    .airesultBox {
      position: absolute;
      top: -25px;
      left: 30%;
      width: 40%;
      h3 {
        font-weight: 600;
        text-align: center;
        padding: 5px;
        background-color: #f8f9fa;
        border: 5px solid $gray-400 !important;
      }
    }

    .preprocessingResultBox {
      position: absolute;
      top: -25px;
      left: 10%;
      width: 80%;
      h3 {
        font-weight: 600;
        text-align: center;
        padding: 5px;
        background-color: #f8f9fa;
        border: 5px solid $gray-400 !important;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    flex-direction: column;

    .area-picture {
      order: 2;
    }
    .area-info {
      order: 1;
      flex: none;
      height: inherit;
    }
  }
}
