@import '~styles/var';
@import '~styles/mixin';

.CervitemMoveIcon {
  cursor: pointer;

  & .cart-btn {
    z-index: 5;
    position: fixed;
    bottom: 160px;
    right: 220px;
  }
  & .order-btn {
    z-index: 5;
    position: fixed;
    bottom: 100px;
    right: 220px;
  }

  .Btn {
    position: relative;

    & .btn {
      z-index: 6;
      position: absolute;
      left: 9px;
      width: 150px;
      border-radius: 4px;
      background: linear-gradient(to bottom, $white, $gray-300);
      box-shadow: 0 4px 2px 0px rgba($gray-600, 0.5);
      font-size: 20px;
      padding: 3px 0;

      &:hover,
      &.checked {
        background: linear-gradient(to bottom, $gray-300, $gray-200);
        color: $white;
      }
    }

    & .icon-btn {
      z-index: 7;
      position: absolute;
      top: 21px;
      background: linear-gradient(to top, $black, $gray-700);
      color: $white;
      border-radius: 100%;
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;

      & .icon {
        transform: rotate(-35deg);
        width: 80%;
        height: 80%;
        margin-bottom: 2px;
      }
    }
  }
}
