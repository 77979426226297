@charset "UTF-8";
/*bootstrap*/
/*변수*/
/*z-index*/
:root {
  --scrollbar-width: calc(100vw - 100%); }

.ZoomWindow {
  position: fixed;
  margin: 0 !important;
  padding: 0 !important;
  z-index: 900;
  transition: left 0.3s;
  top: 3.5rem;
  left: 11rem;
  right: 0;
  bottom: 0;
  background-color: #212529; }
  .ZoomWindow .area-back-button {
    position: absolute;
    left: 5px;
    top: 5px; }
  @media (max-width: 575.98px) {
    .ZoomWindow {
      left: 0 !important;
      top: 0 !important; } }
  .ZoomWindow .area-picture {
    position: relative;
    width: 100%;
    height: 100%; }
    .ZoomWindow .area-picture .zoom-item {
      height: 100%; }
    .ZoomWindow .area-picture .area-title {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-color: rgba(33, 37, 41, 0.3);
      text-align: center;
      color: white;
      padding: 0.7rem; }
    .ZoomWindow .area-picture .area-top-button {
      position: absolute;
      top: 0;
      right: 0;
      padding: 5px; }
      .ZoomWindow .area-picture .area-top-button.left {
        right: auto;
        left: 0; }
    .ZoomWindow .area-picture .area-bottom-button {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 5px; }
    .ZoomWindow .area-picture .btn-popup {
      position: absolute;
      top: 37px;
      right: 5px;
      transform: scale(2);
      transform-origin: top right;
      background-color: transparent;
      border-color: transparent; }
    @media (max-width: 575.98px) {
      .ZoomWindow .area-picture .btn-popup {
        display: none; } }
    .ZoomWindow .area-picture .btn-large-left {
      position: absolute;
      top: calc(50% - 25px);
      left: 5px;
      transform: scale(3);
      transform-origin: top left;
      background-color: transparent;
      border-color: transparent; }
      .ZoomWindow .area-picture .btn-large-left.disabled {
        opacity: 0.3; }
    .ZoomWindow .area-picture .btn-large-right {
      position: absolute;
      top: calc(50% - 38px);
      right: 5px;
      transform: scale(3);
      transform-origin: top right;
      background-color: transparent;
      border-color: transparent; }
      .ZoomWindow .area-picture .btn-large-right.disabled {
        opacity: 0.3; }
