@charset "UTF-8";
/*bootstrap*/
/*변수*/
/*z-index*/
:root {
  --scrollbar-width: calc(100vw - 100%); }

.CervitemMoveIcon {
  cursor: pointer; }
  .CervitemMoveIcon .cart-btn {
    z-index: 5;
    position: fixed;
    bottom: 160px;
    right: 220px; }
  .CervitemMoveIcon .order-btn {
    z-index: 5;
    position: fixed;
    bottom: 100px;
    right: 220px; }
  .CervitemMoveIcon .Btn {
    position: relative; }
    .CervitemMoveIcon .Btn .btn {
      z-index: 6;
      position: absolute;
      left: 9px;
      width: 150px;
      border-radius: 4px;
      background: linear-gradient(to bottom, #fff, #dee2e6);
      box-shadow: 0 4px 2px 0px rgba(108, 117, 125, 0.5);
      font-size: 20px;
      padding: 3px 0; }
      .CervitemMoveIcon .Btn .btn:hover, .CervitemMoveIcon .Btn .btn.checked {
        background: linear-gradient(to bottom, #dee2e6, #e9ecef);
        color: #fff; }
    .CervitemMoveIcon .Btn .icon-btn {
      z-index: 7;
      position: absolute;
      top: 21px;
      background: linear-gradient(to top, #000, #495057);
      color: #fff;
      border-radius: 100%;
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .CervitemMoveIcon .Btn .icon-btn .icon {
        transform: rotate(-35deg);
        width: 80%;
        height: 80%;
        margin-bottom: 2px; }
