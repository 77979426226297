@charset "UTF-8";
/*bootstrap*/
/*변수*/
/*z-index*/
:root {
  --scrollbar-width: calc(100vw - 100%); }

.PictureListCardForAndroid {
  font-size: 20px; }
  .PictureListCardForAndroid .card-header .info {
    font-weight: normal !important;
    color: #813838;
    background-color: #fee2e1; }
  .PictureListCardForAndroid .card-header .btn {
    font-size: 22px; }
  .PictureListCardForAndroid .card-header .back-btn {
    z-index: 900; }
  .PictureListCardForAndroid .dropdown-menu .dropdown-header .btn {
    width: 100%;
    text-align: left; }
  .PictureListCardForAndroid .picture-list {
    flex: 1 0 0%;
    overflow: auto; }
    .PictureListCardForAndroid .picture-list ul {
      margin: 0;
      padding: 0;
      list-style: none; }
    .PictureListCardForAndroid .picture-list > ul > li div.send-title {
      position: sticky;
      top: -1px;
      z-index: 10;
      box-shadow: 0 2px 2px 0px rgba(173, 181, 189, 0.5); }
      .PictureListCardForAndroid .picture-list > ul > li div.send-title .custom-checkbox .custom-control-label::before,
      .PictureListCardForAndroid .picture-list > ul > li div.send-title .custom-checkbox .custom-control-label::after {
        top: 7px;
        width: 16px;
        height: 16px; }
    .PictureListCardForAndroid .picture-list > ul > li > div {
      display: block;
      padding: 5px;
      border-width: 1px 0;
      border-style: solid;
      border-color: #adb5bd;
      background: #e9ecef; }
    .PictureListCardForAndroid .picture-list > ul > li:first-child > div {
      border-top: none; }
    .PictureListCardForAndroid .picture-list > ul > li ul {
      display: flex;
      flex-wrap: wrap;
      padding: 5px 0 0 5px; }
      .PictureListCardForAndroid .picture-list > ul > li ul li {
        width: 352px;
        flex: none;
        margin: 0 5px 5px 0;
        border: 1px solid #dee2e6;
        background-color: #f8f9fa; }
        .PictureListCardForAndroid .picture-list > ul > li ul li.checked {
          border-color: #20a8d8;
          background-color: rgba(32, 168, 216, 0.5); }
          .PictureListCardForAndroid .picture-list > ul > li ul li.checked .picture {
            padding: 4px; }
            .PictureListCardForAndroid .picture-list > ul > li ul li.checked .picture .PictureBox {
              filter: brightness(40%); }
            .PictureListCardForAndroid .picture-list > ul > li ul li.checked .picture .checked-icon {
              position: absolute;
              top: 15px;
              left: 15px;
              width: 50px;
              height: 50px;
              opacity: 0.85; }
        .PictureListCardForAndroid .picture-list > ul > li ul li .picture {
          width: 350px;
          height: 233.33333px;
          cursor: pointer;
          position: relative; }
          .PictureListCardForAndroid .picture-list > ul > li ul li .picture .refresh {
            position: absolute;
            top: 38%;
            left: 40%;
            opacity: 1; }
            .PictureListCardForAndroid .picture-list > ul > li ul li .picture .refresh svg {
              width: 40px;
              height: 40px; }
          .PictureListCardForAndroid .picture-list > ul > li ul li .picture .ai-result {
            position: absolute;
            top: 5px;
            right: 5px;
            width: 80px;
            height: 80px;
            opacity: 0.8;
            border-radius: 100%;
            background-color: rgba(32, 168, 216, 0.6);
            border: 2px solid white; }
            .PictureListCardForAndroid .picture-list > ul > li ul li .picture .ai-result.analyzing {
              background-color: #e57043;
              opacity: 1;
              border: 2px solid black;
              padding: 10px; }
            .PictureListCardForAndroid .picture-list > ul > li ul li .picture .ai-result.fail {
              background-color: red;
              margin: 3px;
              font-weight: bold;
              opacity: 1; }
          .PictureListCardForAndroid .picture-list > ul > li ul li .picture .preprocessing {
            position: absolute;
            bottom: 5px;
            right: 5px;
            opacity: 0.7; }
            .PictureListCardForAndroid .picture-list > ul > li ul li .picture .preprocessing :hover {
              opacity: 0.5; }
        .PictureListCardForAndroid .picture-list > ul > li ul li .take-date {
          padding: 3px; }
      @media (max-width: 1199.98px) {
        .PictureListCardForAndroid .picture-list > ul > li ul li {
          width: 352px; }
          .PictureListCardForAndroid .picture-list > ul > li ul li .picture {
            width: 350px;
            height: 233.33333px;
            cursor: pointer; } }
  .PictureListCardForAndroid .card-footer {
    font-weight: bold; }
    .PictureListCardForAndroid .card-footer .badge {
      vertical-align: 0.1em; }
