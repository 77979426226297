@charset "UTF-8";
/*bootstrap*/
/*변수*/
/*z-index*/
:root {
  --scrollbar-width: calc(100vw - 100%); }

.ZoomPopup {
  position: fixed;
  margin: 0 !important;
  padding: 0 !important;
  z-index: 900;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #212529; }
  .ZoomPopup .area-picture {
    position: relative;
    width: 100%;
    height: 100%; }
    .ZoomPopup .area-picture .zoom-item {
      height: 100%; }
    .ZoomPopup .area-picture .area-title {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-color: rgba(33, 37, 41, 0.3);
      text-align: center;
      color: white;
      padding: 0.7rem; }
    .ZoomPopup .area-picture .area-top-button {
      position: absolute;
      top: 0;
      right: 0;
      padding: 5px; }
      .ZoomPopup .area-picture .area-top-button.left {
        right: auto;
        left: 0; }
    .ZoomPopup .area-picture .area-bottom-button {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 5px; }
    .ZoomPopup .area-picture .btn-large-left {
      position: absolute;
      top: calc(50% - 25px);
      left: 5px;
      transform: scale(3);
      transform-origin: top left;
      background-color: transparent;
      border-color: transparent; }
      .ZoomPopup .area-picture .btn-large-left.disabled {
        opacity: 0.3; }
    .ZoomPopup .area-picture .btn-large-right {
      position: absolute;
      top: calc(50% - 38px);
      right: 5px;
      transform: scale(3);
      transform-origin: top right;
      background-color: transparent;
      border-color: transparent; }
      .ZoomPopup .area-picture .btn-large-right.disabled {
        opacity: 0.3; }
