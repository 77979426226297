@import '~styles/var';

.PictureListCard {
  .card-header.info {
    font-weight: normal !important;
    color: theme-color-level(danger, $alert-color-level);
    background-color: theme-color-level(danger, $alert-bg-level);
  }
  .picture-list {
    flex: 1 0 0%;
    overflow: auto;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    & > ul > li {
      div.send-title {
        position: sticky;
        top: -1px;
        z-index: 10;
        box-shadow: 0 2px 2px 0px rgba($gray-500, 0.5);
      }
      & > div {
        display: block;
        padding: 5px;
        border-width: 1px 0;
        border-style: solid;
        border-color: $gray-500;
        background: $gray-200;
      }

      &:first-child > div {
        border-top: none;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        padding: 5px 0 0 5px;

        li {
          width: $picture-preview-width + 2px;
          flex: none;
          margin: 0 5px 5px 0;
          border: 1px solid $gray-300;
          background-color: $gray-100;

          &.checked {
            border-color: $primary;
            background-color: rgba($primary, 0.5);
          }

          .picture {
            width: $picture-preview-width;
            height: $picture-preview-height;
            cursor: pointer;
            position: relative;

            .refresh {
              position: absolute;
              top: 40%;
              left: 40%;
              opacity: 1;
            }

            .ai-result {
              position: absolute;
              top: 5px;
              right: 5px;
              opacity: 0.6;
              &.analyzing {
                color: greenyellow;
                margin: 3px;
                font-weight: bold;
                opacity: 1;
              }
              &.fail {
                color: red;
                margin: 3px;
                font-weight: bold;
                opacity: 1;
              }
            }

            .preprocessing {
              position: absolute;
              bottom: 5px;
              right: 5px;
              opacity: 0.7;

              :hover {
                opacity: 0.5;
              }
            }
          }
          .take-date {
            padding: 3px;
          }
        }

        @include media-breakpoint-down(lg) {
          li {
            width: $picture-preview-small-width + 2px;
            .picture {
              width: $picture-preview-small-width;
              height: $picture-preview-small-height;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .card-footer {
    font-weight: bold;
    .badge {
      vertical-align: 0.1em;
    }
  }
}
