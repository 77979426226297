@import '~styles/var';
@import '~styles/mixin';

.CervicoResultWindow {
  @include window-layout;

  .area-back-button {
    z-index: 960;
  }

  background-color: $white;
  display: flex;

  .area-picture {
    flex: 1 1 auto;
    position: relative;
    cursor: pointer;
  }
  .area-pdf {
    flex: 1 1 auto;
  }

  .area-info {
    background-color: $gray-800;
    overflow-y: auto;
    flex: 0 0 400px;

    @media (max-width: 950px) {
      flex: 0 0 40%;
    }

    & > * {
      padding: 5px !important;
    }

    .area-picture-list {
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;

      flex: 1;

      .picture {
        border: 2px solid transparent;
        width: $picture-result-width;
        height: $picture-result-height;
        background-size: cover;
        cursor: pointer;
        margin: 0 5px 5px 0;

        &.active {
          border-color: $green;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;

    .area-picture {
      order: 2;
    }
    .area-info {
      order: 1;
      flex: none;
      height: inherit;
    }
  }
}
