@import '~styles/var';
@import 'node_modules/bootstrap/scss/bootstrap';
@import '~styles/common';

//checker 를 안보이게 살정
#checker {
  display: none;
}

.App {
  display: flex;
  flex-direction: column;

  width: 100vw;
  height: 100vh;

  //background: #e4e5e6;
  overflow: hidden;

  header {
    flex: 0 0 $header-height;
    //height: $header-height;

    background-color: white;
  }

  .content-wrap {
    flex: 1 0 0%;
  }
  .nav-tabs {
    border-bottom: 1px solid $gray-500;
  }
  .nav-item {
    font-weight: bold;
    font-size: 1.2rem;
    .active {
      border: 1px solid $gray-500;
      border-bottom-color: white;
    }
  }
  .Notice.card {
    border-radius: 0 0 0.25rem 0.25rem;
    border-top: none;
  }
  .Toastify__toast-body .a {
    text-decoration: none;
  }
  nav {
    width: $nav-width;
    transition: width $nav-transition-time;
    overflow-x: hidden;
    font-size: 1.2em;
    position: fixed;
    height: calc(100vh - #{$header-height});
    //평상시에는 팝업류 보다 낮음
    z-index: 10;
  }

  #content {
    position: relative;
    transition: margin-left $nav-transition-time;
    height: calc(100vh - #{$header-height});
    margin-left: $nav-width;
    overflow-x: hidden;
    background: $background-color;
  }
  .with-padding {
    height: calc(100vh - #{$header-height});
    padding: 10px 5px 0 5px;
  }

  .ws-pre {
    white-space: pre-wrap !important;
  }

  &.hide {
    nav {
      width: $nav-width-hide;
    }
    #content {
      margin-left: $nav-width-hide;
    }
    .layer-window {
      left: $nav-width-hide;
    }
  }

  @include media-breakpoint-down(xs) {
    nav {
      z-index: 1000 !important; //모바일에서는 최상급
    }

    #content {
      margin-left: 0 !important;
    }

    &.hide {
      nav {
        width: 0;
      }
    }

    &.show #content {
      &::before {
        content: '';
        position: fixed;
        top: $header-height;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999;
        background: rgba(0, 0, 0, 0.7);
        animation: opacity $nav-transition-time;
      }
    }
  }

  .test-server {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    font-weight: bold;
    font-size: 1.2rem;
    background-color: red;
    color: white;
    padding: 3px;
    margin: 0 auto;
    animation: blink 1s linear infinite;
    &.next {
      background-color: yellow;
      color: red;
    }
  }

  @keyframes blink {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0.7;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 0.7;
    }
    100% {
      opacity: 0;
    }
  }
  // IE에서만 지원됨.
  .ime {
    -webkit-ime-mode: active;
    -moz-ime-mode: active;
    -ms-ime-mode: active;
    ime-mode: active;
  }

  .fix-width {
    & > .form-group {
      min-width: 280px;
      @include media-breakpoint-up(sm) {
        input,
        select {
          width: auto;
        }
      }
    }
  }
}
