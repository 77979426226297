@charset "UTF-8";
/*bootstrap*/
/*변수*/
/*z-index*/
:root {
  --scrollbar-width: calc(100vw - 100%); }

.CerviAiResultWindowForAndroid {
  position: fixed;
  margin: 0 !important;
  padding: 0 !important;
  z-index: 900;
  transition: left 0.3s;
  top: 3.5rem;
  left: 11rem;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: #212529;
  display: flex; }
  .CerviAiResultWindowForAndroid .area-back-button {
    position: absolute;
    left: 5px;
    top: 5px; }
  @media (max-width: 575.98px) {
    .CerviAiResultWindowForAndroid {
      left: 0 !important;
      top: 0 !important; } }
  .CerviAiResultWindowForAndroid .highlight {
    padding: 2px 5px;
    color: white;
    background-color: #f86c6b; }
  .CerviAiResultWindowForAndroid .area-back-button {
    z-index: 960; }
    .CerviAiResultWindowForAndroid .area-back-button .btn {
      width: 50px;
      height: 50px; }
      .CerviAiResultWindowForAndroid .area-back-button .btn svg {
        width: 25px;
        height: 25px; }
  .CerviAiResultWindowForAndroid .picture-container {
    display: flex;
    flex-direction: column;
    flex: 1 1; }
  .CerviAiResultWindowForAndroid .area-picture {
    flex: 5 1 auto;
    position: relative;
    cursor: pointer; }
  .CerviAiResultWindowForAndroid .area-info {
    background-color: #343a40;
    flex: 0 0 400px;
    overflow-y: auto; }
    .CerviAiResultWindowForAndroid .area-info > * {
      padding: 5px !important; }
    @media (max-width: 768px) {
      .CerviAiResultWindowForAndroid .area-info {
        flex: 0 0 45% !important; } }
  .CerviAiResultWindowForAndroid .area-picture-list {
    margin: 0;
    padding: 0;
    display: flex;
    align-content: flex-start;
    justify-content: center;
    background-color: #212529;
    flex: 1 1; }
    .CerviAiResultWindowForAndroid .area-picture-list .workspace {
      border: 2px solid gray; }
      .CerviAiResultWindowForAndroid .area-picture-list .workspace.active {
        border-color: #4dbd74; }
    .CerviAiResultWindowForAndroid .area-picture-list .picture {
      margin: 0 10px;
      position: relative; }
      .CerviAiResultWindowForAndroid .area-picture-list .picture.active {
        border-color: #4dbd74; }
      .CerviAiResultWindowForAndroid .area-picture-list .picture .represent {
        position: absolute;
        width: 17px;
        height: 17px;
        top: 0;
        right: 0; }
        .CerviAiResultWindowForAndroid .area-picture-list .picture .represent .icon {
          width: 100%;
          height: 100%;
          cursor: pointer; }
      .CerviAiResultWindowForAndroid .area-picture-list .picture .ai-result {
        position: absolute;
        top: 0;
        left: 0; }
        .CerviAiResultWindowForAndroid .area-picture-list .picture .ai-result .badge {
          opacity: 0.85;
          height: 30px;
          font-size: 18px;
          margin-bottom: 3px; }
        .CerviAiResultWindowForAndroid .area-picture-list .picture .ai-result .probability {
          width: 60px; }
  .CerviAiResultWindowForAndroid .classResult-grid {
    text-align: center;
    font-size: 20px; }
    .CerviAiResultWindowForAndroid .classResult-grid .bg-danger-half {
      border-color: transparent !important;
      background-image: linear-gradient(90deg, #f86c6b 50%, #f8f9fa 0) !important;
      background-origin: border-box !important; }
    .CerviAiResultWindowForAndroid .classResult-grid .bg-normal {
      border-color: transparent !important;
      background-image: linear-gradient(90deg, #20a8d8 25%, #f8f9fa 0) !important;
      background-origin: border-box !important; }
    .CerviAiResultWindowForAndroid .classResult-grid .bg-atypical {
      border-color: transparent !important;
      background-image: linear-gradient(90deg, #f8f9fa 25%, #4dbd74 25% 50%, #f8f9fa 0) !important;
      background-origin: border-box !important; }
    .CerviAiResultWindowForAndroid .classResult-grid .bg-positive1 {
      border-color: transparent !important;
      background-image: linear-gradient(90deg, #f8f9fa 50%, #ff7f50 50% 75%, #f8f9fa 0) !important;
      background-origin: border-box !important; }
    .CerviAiResultWindowForAndroid .classResult-grid .bg-positive2 {
      border-color: transparent !important;
      background-image: linear-gradient(90deg, #f8f9fa 75%, #e74c3d 0) !important;
      background-origin: border-box !important; }
  .CerviAiResultWindowForAndroid .classResult-TD {
    text-align: center;
    color: #343a40;
    margin: 20px 0 20px 0; }
  .CerviAiResultWindowForAndroid .graph-container {
    color: white;
    margin: 5px 0 5px 0; }
  .CerviAiResultWindowForAndroid .descr-container .airesultBox {
    position: absolute;
    top: -25px;
    left: 30%;
    width: 40%; }
    .CerviAiResultWindowForAndroid .descr-container .airesultBox h3 {
      font-weight: 600;
      text-align: center;
      padding: 5px;
      background-color: #f8f9fa;
      border: 5px solid #ced4da !important; }
  .CerviAiResultWindowForAndroid .descr-container .preprocessingResultBox {
    position: absolute;
    top: -25px;
    left: 10%;
    width: 80%; }
    .CerviAiResultWindowForAndroid .descr-container .preprocessingResultBox h3 {
      font-weight: 600;
      text-align: center;
      padding: 5px;
      background-color: #f8f9fa;
      border: 5px solid #ced4da !important; }
  @media (max-width: 767.98px) {
    .CerviAiResultWindowForAndroid {
      flex-direction: column; }
      .CerviAiResultWindowForAndroid .area-picture {
        order: 2; }
      .CerviAiResultWindowForAndroid .area-info {
        order: 1;
        flex: none;
        height: inherit; } }
