$draw-navi-width: 50px;
$pic-background-color-dark: #333;

.navi-wrap {
  position: relative;
  width: $draw-navi-width;

  .navi {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;

    display: flex;
    flex-direction: column;
    background: $pic-background-color-dark;

    & > .nav-spacer {
      height: 40px;
    }

    & > div {
      display: block;
      line-height: 40px;
      font-size: 20px;
      text-align: center;
      color: white;

      svg {
        pointer-events: none;
      }

      &.active {
        background-color: red;
      }

      &.disabled-ur {
        color: gray;
        cursor: default;
      }

      &:hover {
        background-color: #444;
      }

      cursor: pointer;

      &.navi-move {
        div {
          cursor: pointer !important;
          color: white !important;
          text-align: center;
          flex-grow: 1;

          &:hover {
            svg {
              transform: scale(1.05);
            }
          }

          &:first-child {
            padding-left: 5px;
          }

          &:last-child {
            padding-right: 5px;
          }
        }
      }
    }
  }
}
