@import '~styles/var';
@import '~styles/mixin';

.CervicoResultCompareWindow {
  @include window-layout;

  background-color: $gray-900;
  display: flex;
  flex-direction: column;

  .area-button {
    flex: none;
    padding: 5px;
    display: flex;
    justify-content: flex-end;
    & > button {
      margin-left: 5px;
    }
  }

  .area-result {
    flex: 1;
    display: flex;
  }

  .area-worklist {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .loading {
      flex: 1 1 auto;
      background: $gray-800;
      position: relative;
    }

    .area-picture {
      flex: 1 1 auto;
      position: relative;
      cursor: pointer;
      height: 100%;
    }
    .area-info {
      flex: 0 0 350px;
      overflow: auto;
      background-color: $gray-800;

      & > * {
        padding: 5px !important;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;

    .area-picture {
      order: 2;
    }
    .area-info {
      order: 1;
      flex: none;
      height: inherit;
    }
  }
}
