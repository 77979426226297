@import '~styles/var';

.Login {
  min-height: 100%;

  .version-box {
    position: absolute;
    right: 0;
    bottom: 0;
    color: white;
    margin: 2px;
  }

  .logo-img {
    @media (max-width: 500px) {
      width: 60vw;
    }
  }
}
