@charset "UTF-8";
/*bootstrap*/
/*변수*/
/*z-index*/
:root {
  --scrollbar-width: calc(100vw - 100%); }

.PdfWindow {
  position: fixed;
  margin: 0 !important;
  padding: 0 !important;
  z-index: 900;
  transition: left 0.3s;
  top: 3.5rem;
  left: 11rem;
  right: 0;
  bottom: 0;
  background-color: #212529;
  display: flex;
  flex-direction: column; }
  .PdfWindow .area-back-button {
    position: absolute;
    left: 5px;
    top: 5px; }
  @media (max-width: 575.98px) {
    .PdfWindow {
      left: 0 !important;
      top: 0 !important; } }
  .PdfWindow .area-button {
    flex: none;
    padding: 5px;
    display: flex;
    justify-content: flex-end; }
    .PdfWindow .area-button > button {
      margin-left: 5px; }
  .PdfWindow .area-pdf {
    flex: 1;
    display: flex;
    overflow: hidden !important; }
    .PdfWindow .area-pdf object,
    .PdfWindow .area-pdf embed,
    .PdfWindow .area-pdf iframe {
      width: 100%;
      height: 100%;
      border: none; }
  @media (max-width: 767.98px) {
    .PdfWindow {
      flex-direction: column; }
      .PdfWindow .area-picture {
        order: 2; }
      .PdfWindow .area-info {
        order: 1;
        flex: none;
        height: inherit; } }
