@import '~styles/var';
@import '~styles/mixin';

.ZoomWindow {
  @include window-layout;

  background-color: $gray-900;

  .area-picture {
    position: relative;
    width: 100%;
    height: 100%;

    .zoom-item {
      height: 100%;
    }

    .area-title {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-color: rgba($gray-900, 0.3);
      text-align: center;
      color: white;
      padding: 0.7rem;
    }

    .area-top-button {
      position: absolute;
      top: 0;
      right: 0;
      padding: 5px;
      &.left {
        right: auto;
        left: 0;
      }
    }

    .area-bottom-button {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 5px;
    }

    .btn-popup {
      position: absolute;
      top: 37px;
      right: 5px;
      transform: scale(2);
      transform-origin: top right;
      background-color: transparent;
      border-color: transparent;
    }

    @include media-breakpoint-down(xs) {
      .btn-popup {
        display: none;
      }
    }

    .btn-large-left {
      position: absolute;
      top: calc(50% - 25px);
      left: 5px;
      transform: scale(3);
      transform-origin: top left;
      background-color: transparent;
      border-color: transparent;
      &.disabled {
        opacity: 0.3;
      }
    }

    .btn-large-right {
      position: absolute;
      top: calc(50% - 38px);
      right: 5px;
      transform: scale(3);
      transform-origin: top right;
      background-color: transparent;
      border-color: transparent;
      &.disabled {
        opacity: 0.3;
      }
    }
  }
}
