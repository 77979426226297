@import '~styles/var';
@import '~styles/mixin';

.PdfWindow {
  @include window-layout;

  background-color: $gray-900;
  display: flex;
  flex-direction: column;

  .area-button {
    flex: none;
    padding: 5px;
    display: flex;
    justify-content: flex-end;
    & > button {
      margin-left: 5px;
    }
  }

  .area-pdf {
    flex: 1;
    display: flex;
    overflow: hidden !important;

    object,
    embed,
    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;

    .area-picture {
      order: 2;
    }
    .area-info {
      order: 1;
      flex: none;
      height: inherit;
    }
  }
}
