@import '~styles/var';

.CervicoRequest {
  .form-row {
    &.history {
      .form-group {
        flex-wrap: wrap;
        width: 100%;
        select {
          flex: 0 0 150px;
        }
        input.descr {
          flex: 1 0 230px;
        }
        div.break {
          flex-basis: 100%;
        }
      }
    }
    .custom-checkbox {
      label {
        margin-right: 0;
      }
    }
  }

  .defer-title {
    color: $red;
  }

  .right {
    .btn-container {
      display: flex;
      justify-content: space-between;
      //padding-bottom: 1rem;
      & > div {
        flex: 1;
        padding: 0 5px;
      }
      .btn {
        padding: 0.5rem 0rem;
      }
    }
  }
  //sm 보다 크면
  @include media-breakpoint-up(sm) {
    .check label.descr {
      display: none;
    }
  }

  //lg 보다 크면
  @include media-breakpoint-up(lg) {
    display: flex;
    height: 100%;

    .right {
      width: 430px;
      flex: 0 0 430px;
    }
  }

  //1400 보다 크면
  @media (min-width: 1400px) {
    .right {
      width: 650px;
      flex: 0 0 650px;

      label.descr {
        display: none;
      }
      div.break {
        display: none;
      }
    }

    .DataGrid.border-grid {
      height: 150px !important;
    }
  }
}

@media (max-width: 1399px) {
  .CervicoRequest .right {
    .form-group {
      input.descr {
        margin-top: 5px;
        margin-left: 0px !important;
      }
    }
    .btn {
      font-size: 1rem;
    }
  }
}
