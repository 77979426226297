@import '~styles/var';

.PatForm {
  .form-row {
    @include media-breakpoint-up(sm) {
      .form-group.fix-width {
        width: 240px;
        input,
        select {
          width: auto;
        }
      }
    }

    &.history {
      .form-group {
        flex-wrap: wrap;
        width: 100%;
        select {
          flex: 0 0 150px;
        }
        input.descr {
          flex: 1 0 230px;
        }
        div.break {
          flex-basis: 100%;
        }
      }
    }
    .custom-checkbox {
      label {
        margin-right: 0;
      }
    }
  }

  .defer-title {
    color: $red;
  }
  //sm 보다 크면
  @include media-breakpoint-up(sm) {
    .check label.descr {
      display: none;
    }
  }

  //1400 보다 크면
  @media (min-width: 1400px) {
    label.descr {
      display: none;
    }
    div.break {
      display: none;
    }

    .DataGrid.border-grid {
      height: 150px !important;
    }
  }

  @media (max-width: 1399px) {
    .form-group {
      input.descr {
        margin-top: 5px;
        margin-left: 0px !important;
      }
    }
  }
}
