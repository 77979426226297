@charset "UTF-8";
/*bootstrap*/
/*변수*/
/*z-index*/
:root {
  --scrollbar-width: calc(100vw - 100%); }

.accordion-group {
  overflow: hidden;
  margin: 0;
  padding: 0;
  list-style: none;
  width: calc(100vw - 11rem);
  height: calc(100vh - 3.5rem - 20px);
  display: flex;
  align-items: center;
  transition: all 800ms ease; }
  .accordion-group li {
    cursor: pointer;
    position: relative;
    display: flex;
    overflow: hidden;
    margin: 0;
    padding: 2em;
    list-style: none;
    width: 16.66666667%;
    height: inherit;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 800ms ease-in-out; }
    .accordion-group li.first {
      background-image: url("../../resources/cervitem/1.jpg"); }
    .accordion-group li.second {
      background-image: url("../../resources/cervitem/2.jpg"); }
    .accordion-group li.third {
      background-image: url("../../resources/cervitem/3.jpg"); }
    .accordion-group li.fourth {
      background-image: url("../../resources/cervitem/4.jpeg"); }
    .accordion-group li:hover, .accordion-group li.out {
      width: 50% !important; }
      .accordion-group li:hover .accordion-overlay.first, .accordion-group li.out .accordion-overlay.first {
        opacity: 0; }
      .accordion-group li:hover .accordion-overlay.second, .accordion-group li.out .accordion-overlay.second {
        opacity: 0; }
      .accordion-group li:hover .accordion-overlay.third, .accordion-group li.out .accordion-overlay.third {
        opacity: 0; }
      .accordion-group li:hover .accordion-overlay.fourth, .accordion-group li.out .accordion-overlay.fourth {
        opacity: 0; }
      .accordion-group li:hover h2, .accordion-group li.out h2 {
        color: #000a3d; }
    .accordion-group li:not(:hover) {
      width: 16.66666667%; }
    .accordion-group li .accordion-overlay {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      transition: all 800ms ease-in-out; }
      .accordion-group li .accordion-overlay.first {
        background-color: #0e3260;
        opacity: 0.8; }
      .accordion-group li .accordion-overlay.second {
        background-color: #32507a;
        opacity: 0.8; }
      .accordion-group li .accordion-overlay.third {
        background-color: #596980;
        opacity: 0.8; }
      .accordion-group li .accordion-overlay.fourth {
        background-color: #6a85a6;
        opacity: 0.8; }
    .accordion-group li h2 {
      position: fixed;
      color: #fff;
      font-family: 'Noto Sans KR', Arial, sans-serif;
      letter-spacing: 0.15rem;
      padding: 0;
      top: 75px; }
