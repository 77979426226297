@import '~styles/var';
@import '~styles/mixin';

.Nav-Container {
  height: $nav-bar-height;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .category-container {
    background: linear-gradient(to right, $nav-bar-start, $nav-bar-end);
    box-shadow: 3px 3px 3px $gray-500;
    color: $gray-300;
    font-size: 20px;
    padding: 0px 4rem;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    width: calc(100vw - #{$nav-width});
    font-family: 'Noto Sans KR', Arial, sans-serif;
    font-weight: 500;
    position: fixed;
    z-index: 100;

    li {
      // flex-grow: 1;
      cursor: pointer;
      width: 25%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 0px;

      &:hover,
      &.checked {
        border-bottom: 7px solid transparent;
        color: $white;
        border-image: linear-gradient(to right, $nav-border-start 0%, $nav-border-end 100%);
        border-image-slice: 1;
      }
    }
  }
  @media (max-width: 1499px) {
    .category-container {
      display: flex;
      flex-direction: row;
      flex: 1;
      padding: 4px 4rem;

      & .icon-btn {
        cursor: pointer;
      }

      ul {
        padding: 0;
        box-shadow: 0px 2px 3px #b5babd;
      }

      li {
        width: 100%;
        padding: 5px;

        &:hover,
        &.checked {
          border-bottom: 0;
          background: linear-gradient(to right, $nav-border-start, $nav-border-end);
        }
      }
    }
  }

  .back-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .icon-with-text {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .category-container-without-menu {
    padding: 10px 4rem;
    justify-content: space-between;
  }

  .search-icon {
    background-color: $white;
    color: $gray-600;
    border: 0;

    &:hover {
      background: linear-gradient(to left, $nav-border-start, $nav-border-end);
      color: $white;
    }
    &:focus {
      background-color: $white;
      color: $gray-600;
    }
  }
}
