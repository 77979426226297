@mixin window-layout {
  position: fixed;
  margin: 0 !important;
  padding: 0 !important;
  z-index: $z-index-window-layer;
  transition: left 0.3s;

  top: $header-height;
  left: $nav-width;
  right: 0;
  bottom: 0;

  .area-back-button {
    position: absolute;
    left: 5px;
    top: 5px;
  }

  @include media-breakpoint-down(xs) {
    left: 0 !important;
    top: 0 !important;
  }
}

@mixin android-layout {
  position: fixed;
  margin: 0 !important;
  padding: 0 !important;
  z-index: $z-index-window-layer;
  transition: left 0.3s;

  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;

  .area-back-button {
    position: absolute;
    left: 5px;
    top: 5px;
  }
}
