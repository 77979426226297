@charset "UTF-8";
/*bootstrap*/
/*변수*/
/*z-index*/
:root {
  --scrollbar-width: calc(100vw - 100%); }

.ProductWindow {
  position: fixed;
  margin: 0 !important;
  padding: 0 !important;
  z-index: 900;
  transition: left 0.3s;
  top: 3.5rem;
  left: 11rem;
  right: 0;
  bottom: 0;
  background-color: #f8f9fa; }
  .ProductWindow .area-back-button {
    position: absolute;
    left: 5px;
    top: 5px; }
  @media (max-width: 575.98px) {
    .ProductWindow {
      left: 0 !important;
      top: 0 !important; } }
  .ProductWindow .area {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll; }
    @media (max-width: 575.98px) {
      .ProductWindow .area .btn-popup {
        display: none; } }
