@import '~styles/var';

.CerviAi {
  display: flex;
  height: 100%;

  & > .container-fluid.h-flex > .card {
    flex-grow: 1;

    &.searchFormCard {
      flex-grow: 0;
    }
  }
  .ResultPreviewCard {
    .picture {
      min-height: 250px;
      flex: 4 1;
      position: relative;
    }

    .result-preview-body-area {
      height: 250px;
      display: flex;
      flex-direction: column;

      .ofy-auto {
        overflow-y: auto;
      }
    }
  }

  .reg-date {
    padding: 3px;
  }

  .picture-list-card {
    flex: 1 1;
  }

  .CerviAiPictureList {
    flex: 1 1;
    overflow: auto;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    & > ul > li {
      ul {
        display: flex;
        padding: 5px 0 0 5px;

        li {
          flex: none;
          margin: 0 5px 5px 0;
          background-color: $gray-100;

          &.checked {
            border-color: $primary;
            background-color: rgba($primary, 0.5);

            .card-header,
            .card-footer,
            ul,
            ul > li {
              border-color: $primary;
              background-color: rgba($primary, 0.5);
            }
          }

          .card {
            width: $picture-preview-width + 2px;

            .card-img {
              width: $picture-preview-width;
              height: $picture-preview-height;
              cursor: pointer;
              position: relative;
              background-color: #444;
            }

            .card-footer {
              & > * {
                padding: 0.5rem 1rem;
                font-size: 1.25rem;
                line-height: 1.5;
                border-radius: 0.3;
              }
            }
            .process {
              color: green;
            }

            .process-error {
              color: red;
            }
          }
        }
      }
    }
  }
}
