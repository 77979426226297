@charset "UTF-8";
/*bootstrap*/
/*변수*/
/*z-index*/
:root {
  --scrollbar-width: calc(100vw - 100%); }

.PatInfoForAndroid .modal-header {
  padding: 1rem 2rem; }
  .PatInfoForAndroid .modal-header .modal-title {
    font-size: 25px; }
  .PatInfoForAndroid .modal-header .close {
    font-size: 35px; }

.PatInfoForAndroid .modal-body {
  padding: 1rem 2rem; }
  .PatInfoForAndroid .modal-body .row {
    margin-bottom: 10px; }
    .PatInfoForAndroid .modal-body .row.end {
      margin-bottom: 0; }
    .PatInfoForAndroid .modal-body .row .form-label {
      display: flex;
      align-content: center; }
      .PatInfoForAndroid .modal-body .row .form-label .form-control {
        width: 60%; }
      .PatInfoForAndroid .modal-body .row .form-label label {
        font-size: 20px;
        margin-right: 40px; }
      .PatInfoForAndroid .modal-body .row .form-label .custom-checkbox .custom-control-label::before,
      .PatInfoForAndroid .modal-body .row .form-label .custom-checkbox .custom-control-label::after {
        top: 7px;
        width: 16px;
        height: 16px; }
      .PatInfoForAndroid .modal-body .row .form-label .custom-radio .custom-control-label::before,
      .PatInfoForAndroid .modal-body .row .form-label .custom-radio .custom-control-label::after {
        top: 7px;
        width: 16px;
        height: 16px; }
      .PatInfoForAndroid .modal-body .row .form-label.col-md-6 .form-group {
        display: flex;
        width: 100%; }
      .PatInfoForAndroid .modal-body .row .form-label.col-md-12 .form-group {
        width: 100%;
        display: flex; }
        .PatInfoForAndroid .modal-body .row .form-label.col-md-12 .form-group .form-control {
          width: 65%; }

.PatInfoForAndroid .modal-footer {
  padding: 1rem 2rem; }
  .PatInfoForAndroid .modal-footer .btn {
    font-size: 23px; }
