@import '~styles/var';

.AiListCardForAndroid {
  font-size: 20px;

  .card-header {
    .info {
      font-weight: normal !important;
      color: theme-color-level(danger, $alert-color-level);
      background-color: theme-color-level(danger, $alert-bg-level);
    }

    .btn {
      font-size: 22px;
    }

    .back-btn {
      z-index: 900;
    }

    .search {
      .card-header {
        padding: 0.5rem 0.5rem;
      }

      .card-body {
        height: auto;

        .form-row {
          justify-content: space-between;
        }
      }
    }
  }

  .picture-list {
    flex: 1 0 0%;
    overflow: auto;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    & > ul > li {
      div.send-title {
        position: sticky;
        top: -1px;
        z-index: 10;
        box-shadow: 0 2px 2px 0px rgba($gray-500, 0.5);

        display: flex;
        justify-content: space-between;

        .custom-checkbox .custom-control-label::before,
        .custom-checkbox .custom-control-label::after {
          top: 7px;
          width: 16px;
          height: 16px;
        }
      }
      & > div {
        display: block;
        padding: 5px;
        border-width: 1px 0;
        border-style: solid;
        border-color: $gray-500;
        background: $gray-200;
      }

      &:first-child > div {
        border-top: none;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        padding: 5px 0 0 5px;

        li {
          width: $picture-preview-android-width + 2px;
          flex: none;
          margin: 0 5px 5px 0;
          border: 1px solid $gray-300;
          background-color: $gray-100;

          &.checked {
            border-color: $primary;
            background-color: rgba($primary, 0.5);

            .picture {
              padding: 4px;

              .PictureBox {
                filter: brightness(40%);
              }

              .checked-icon {
                position: absolute;
                top: 15px;
                left: 15px;
                width: 50px;
                height: 50px;
                opacity: 0.85;
              }
            }
          }

          .picture {
            width: $picture-preview-android-width;
            height: $picture-preview-android-height;
            cursor: pointer;
            position: relative;
            padding: 4px;

            &.TD {
              background-color: $gray-600;
            }
            &.N {
              background-color: $blue;
            }
            &.A {
              background-color: $green;
            }
            &.P1 {
              background-color: $coral;
            }
            &.P2 {
              background-color: $crimsonRed;
            }
            &.P3 {
              background-color: $crimsonRed;
            }

            .refresh {
              position: absolute;
              top: 38%;
              left: 40%;
              opacity: 1;

              svg {
                width: 40px;
                height: 40px;
              }
            }

            .ai-result {
              position: absolute;
              top: 5px;
              right: 5px;
              width: 80px;
              height: 80px;
              opacity: 0.8;
              border-radius: 100%;
              background-color: rgba(32, 168, 216, 0.6);
              border: 2px solid white;
              &.analyzing {
                background-color: #e57043;
                opacity: 1;
                border: 2px solid black;
                padding: 10px;
              }
              &.fail {
                background-color: red;
                margin: 3px;
                font-weight: bold;
                opacity: 1;
              }
            }
          }
          .take-date {
            padding: 3px;
          }
        }

        @include media-breakpoint-down(lg) {
          li {
            width: $picture-preview-android-width + 2px;
            .picture {
              width: $picture-preview-android-width;
              height: $picture-preview-android-height;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .card-footer {
    font-weight: bold;
    .badge {
      vertical-align: 0.1em;
    }
  }
}
