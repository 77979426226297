@import '~styles/var';

.CerviBoxCopyWindow {
  .thumb-list {
    display: flex;
    height: $picture-preview-large-height + 60px;
    flex-direction: column;

    ul {
      display: flex;
      flex-wrap: wrap;
      overflow-y: auto;
      padding: 5px 0 0 5px;
      list-style: none;
      margin: 0;

      li {
        width: $picture-preview-large-width + 2px;
        flex: none;
        margin: 0 5px 5px 0;
        border: 1px solid $gray-300;
        background-color: $gray-100;

        &.checked {
          border-color: $primary;
          background-color: rgba($primary, 0.5);
        }

        .picture {
          width: $picture-preview-large-width;
          height: $picture-preview-large-height;
          cursor: pointer;
        }
        .take-date {
          padding: 3px;
          align-items: center;
        }
      }
    }
  }
}
