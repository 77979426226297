@import '~styles/var';

.CustomPagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  bottom: 10px;
  box-shadow: 0 2px 5px 0 $pagination-shadow;
}
