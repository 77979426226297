@import '~styles/var';

.ProgressLayer {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: $mask-color;
  z-index: $z-index-progress-layer;

  .p-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400px;
    margin-left: -200px;
    margin-top: -30px;
    background-color: white;
    border-radius: 3px;
    padding: 10px;
    box-shadow: 0 0 10px 5px rgba($gray-500, 0.5);

    .progress {
      height: 20px;
    }
  }
}
