@import '~styles/var';

.CervicoResultTable {
  table,
  th,
  td {
    border-collapse: collapse;
    border: 1px solid $gray-400;
  }
  table {
    width: 100%;
    background-color: white;
  }
  th,
  td {
    padding: 3px;
  }
  th {
    background-color: $gray-200;
    width: 100px;
    text-align: right;
  }
  & > div {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
