@charset "UTF-8";
/*bootstrap*/
/*변수*/
/*z-index*/
:root {
  --scrollbar-width: calc(100vw - 100%); }

.CervitemCart .table th {
  text-align: center; }

.CervitemCart .table td {
  vertical-align: middle; }

.CervitemCart .Cart-Container {
  max-width: 1020px;
  width: 100%; }

.CervitemCart .cart-orderbtn-container {
  width: 100%;
  border-top: 1px solid black; }

.CervitemCart .empty-list {
  text-align: center;
  font-size: 20px;
  font-weight: 500; }

.CervitemCart .input-box {
  display: flex;
  align-items: center; }

.CervitemCart .cart-item .item-info-box {
  margin-left: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.CervitemCart .cart-item .item-picture {
  width: 100px;
  height: 100px; }

.CervitemCart .item-delete-btn {
  display: flex;
  justify-content: center; }

.CervitemCart .item-quantity-container {
  display: flex;
  width: 80px;
  border: 1px solid #ccc;
  margin: 0;
  transform: scale(0.9); }
  .CervitemCart .item-quantity-container input {
    width: 100%;
    text-align: center;
    border: 0;
    border-right: 1px solid #ccc; }
  .CervitemCart .item-quantity-container .item-quantity-btn-container button {
    width: 100%;
    height: 50%;
    border: 0;
    background-color: #fff; }
  .CervitemCart .item-quantity-container .item-quantity-btn-container .item-quantity-plus {
    border-bottom: 1px solid #ccc; }

.CervitemCart .item-price {
  font-size: 15px; }

.CervitemCart input[type='checkbox'] {
  cursor: pointer;
  zoom: 1.3; }
