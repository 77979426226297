@charset "UTF-8";
/*bootstrap*/
/*변수*/
/*z-index*/
:root {
  --scrollbar-width: calc(100vw - 100%); }

.CerviAiResultWindowWithoutPatInfoForAndroid {
  position: fixed;
  margin: 0 !important;
  padding: 0 !important;
  z-index: 900;
  transition: left 0.3s;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background-color: #fff;
  display: flex; }
  .CerviAiResultWindowWithoutPatInfoForAndroid .area-back-button {
    position: absolute;
    left: 5px;
    top: 5px; }
  .CerviAiResultWindowWithoutPatInfoForAndroid .highlight {
    padding: 2px 5px;
    color: white;
    background-color: #f86c6b; }
  .CerviAiResultWindowWithoutPatInfoForAndroid .area-back-button {
    z-index: 960; }
    .CerviAiResultWindowWithoutPatInfoForAndroid .area-back-button .btn {
      width: 50px;
      height: 50px; }
      .CerviAiResultWindowWithoutPatInfoForAndroid .area-back-button .btn svg {
        width: 25px;
        height: 25px; }
  .CerviAiResultWindowWithoutPatInfoForAndroid .picture-container {
    display: flex;
    flex-direction: column;
    flex: 1 1; }
  .CerviAiResultWindowWithoutPatInfoForAndroid .area-picture {
    flex: 3 1 auto;
    position: relative;
    cursor: pointer;
    background-color: #212529; }
    .CerviAiResultWindowWithoutPatInfoForAndroid .area-picture .inner-picture {
      pointer-events: none; }
  .CerviAiResultWindowWithoutPatInfoForAndroid .area-info {
    background-color: #343a40;
    flex: 0 0 400px;
    overflow-y: auto; }
    @media (max-width: 768px) {
      .CerviAiResultWindowWithoutPatInfoForAndroid .area-info {
        flex: 0 0 45% !important; } }
    .CerviAiResultWindowWithoutPatInfoForAndroid .area-info > * {
      padding: 5px !important; }
  .CerviAiResultWindowWithoutPatInfoForAndroid .area-picture-list {
    margin: 0;
    padding: 0;
    display: flex;
    align-content: flex-start;
    background-color: #212529;
    flex: 1 1;
    height: 213.33333px;
    overflow-x: auto; }
    .CerviAiResultWindowWithoutPatInfoForAndroid .area-picture-list .picture {
      border: 2px solid transparent;
      width: 25%;
      margin: 0 10px;
      display: flex;
      justify-content: center;
      min-width: 15%; }
      @media (max-width: 1250px) {
        .CerviAiResultWindowWithoutPatInfoForAndroid .area-picture-list .picture {
          min-width: 25%; } }
      .CerviAiResultWindowWithoutPatInfoForAndroid .area-picture-list .picture.active {
        border-color: #4dbd74; }
  .CerviAiResultWindowWithoutPatInfoForAndroid .classResult-grid {
    text-align: center;
    font-size: 20px;
    transition: all 0.2s ease-in-out; }
    .CerviAiResultWindowWithoutPatInfoForAndroid .classResult-grid .bg-danger-half {
      border-color: transparent !important;
      background-image: linear-gradient(90deg, #f86c6b 50%, #f8f9fa 0) !important;
      background-origin: border-box !important; }
    .CerviAiResultWindowWithoutPatInfoForAndroid .classResult-grid .bg-normal {
      border-color: transparent !important;
      background-image: linear-gradient(90deg, #20a8d8 25%, #f8f9fa 0) !important;
      background-origin: border-box !important; }
    .CerviAiResultWindowWithoutPatInfoForAndroid .classResult-grid .bg-atypical {
      border-color: transparent !important;
      background-image: linear-gradient(90deg, #f8f9fa 25%, #4dbd74 25% 50%, #f8f9fa 0) !important;
      background-origin: border-box !important; }
    .CerviAiResultWindowWithoutPatInfoForAndroid .classResult-grid .bg-positive1 {
      border-color: transparent !important;
      background-image: linear-gradient(90deg, #f8f9fa 50%, #ff7f50 50% 75%, #f8f9fa 0) !important;
      background-origin: border-box !important; }
    .CerviAiResultWindowWithoutPatInfoForAndroid .classResult-grid .bg-positive2 {
      border-color: transparent !important;
      background-image: linear-gradient(90deg, #f8f9fa 75%, #e74c3d 0) !important;
      background-origin: border-box !important; }
  .CerviAiResultWindowWithoutPatInfoForAndroid .classResult-TD {
    text-align: center;
    color: #343a40;
    margin: 20px 0 20px 0; }
  .CerviAiResultWindowWithoutPatInfoForAndroid .graph-container {
    color: white;
    margin: 5px 0 5px 0; }
  .CerviAiResultWindowWithoutPatInfoForAndroid .descr-container .airesultBox {
    position: absolute;
    top: -25px;
    left: 30%;
    width: 40%; }
    .CerviAiResultWindowWithoutPatInfoForAndroid .descr-container .airesultBox h3 {
      font-weight: 600;
      text-align: center;
      padding: 5px;
      background-color: #f8f9fa;
      border: 5px solid #ced4da !important; }
  @media (max-width: 767.98px) {
    .CerviAiResultWindowWithoutPatInfoForAndroid {
      flex-direction: column; }
      .CerviAiResultWindowWithoutPatInfoForAndroid .area-picture {
        order: 2; }
      .CerviAiResultWindowWithoutPatInfoForAndroid .area-info {
        order: 1;
        flex: none;
        height: inherit; } }
