@charset "UTF-8";
/*bootstrap*/
/*변수*/
/*z-index*/
:root {
  --scrollbar-width: calc(100vw - 100%); }

.SmsArea-yzone {
  width: 618px;
  transition: right 0.3s, box-shadow 0.3s;
  top: 3.5rem;
  right: 0;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  height: calc(100vh - 3.5rem);
  z-index: 10;
  background-color: #e4e5e6;
  box-shadow: -3px 0 5px -1px rgba(0, 0, 0, 0.3); }
  .SmsArea-yzone .card-toolbar div {
    display: inline;
    margin-right: 0px; }
    .SmsArea-yzone .card-toolbar div button {
      margin-left: 5px; }
  .SmsArea-yzone .container-fluid {
    padding-top: 10px; }
  .SmsArea-yzone .tip {
    transition: right 0.3s, opacity 0.3s;
    position: fixed;
    top: calc(3.5rem + 10px);
    right: 618px;
    z-index: 11;
    background-color: #e83e8c;
    opacity: 0.8;
    color: #fff;
    padding: 3px;
    font-size: 1.8rem;
    line-height: 3rem;
    vertical-align: middle;
    text-align: center;
    width: 3rem;
    height: 3rem;
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
    cursor: pointer; }
    .SmsArea-yzone .tip .badge {
      position: absolute;
      right: 1px;
      font-size: 10px;
      background-color: rgba(255, 193, 7, 0.6); }
    .SmsArea-yzone .tip svg {
      vertical-align: initial !important; }
    .SmsArea-yzone .tip:hover {
      opacity: 1; }
  .SmsArea-yzone .ag-cell.message {
    white-space: normal;
    line-height: 1.4em;
    padding-top: 5px;
    padding-bottom: 5px; }
  .SmsArea-yzone .ag-cell.invalid {
    background-color: rgba(248, 108, 107, 0.5); }
  .SmsArea-yzone .ag-theme-balham .ag-cell.ag-cell-inline-editing {
    height: 100%; }
    .SmsArea-yzone .ag-theme-balham .ag-cell.ag-cell-inline-editing div {
      height: 100%; }
  .SmsArea-yzone .actionBtns {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .SmsArea-yzone.hide {
    right: -618px;
    box-shadow: none; }
    .SmsArea-yzone.hide .tip {
      right: 0px; }
  .SmsArea-yzone .container-fluid {
    padding-left: 5px;
    padding-right: 2.5px; }
    .SmsArea-yzone .container-fluid form .form-group {
      flex: 0 0 auto;
      width: auto;
      padding-left: 0;
      padding-right: 0;
      margin-right: 1rem;
      margin-bottom: 0.2rem;
      display: flex;
      align-items: center; }
      .SmsArea-yzone .container-fluid form .form-group label {
        text-align: right;
        margin: 0 2rem;
        white-space: nowrap; }
    .SmsArea-yzone .container-fluid .col-lg-6 button {
      height: 100%; }

@media (max-width: 1199.98px) {
  .SmsArea {
    width: calc(100vw - 30rem); }
    .SmsArea .tip {
      right: calc(100vw - 30rem); } }
