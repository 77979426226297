.CervitemOrder {
  .item-picture {
    width: 80px;
    height: 80px;
  }
  .order-item-container {
    width: 90%;
  }
  .item-info {
    text-overflow: ellipsis;
  }
  .item-name {
    font-size: 17px;
  }
  .text-decoration-line-through {
    text-decoration: line-through;
  }
}
