@import '~styles/var';
@import '~styles/mixin';

.CervitemProduct {
  width: 100%;
  height: 100%;

  .Cervitem-Info {
    height: $picture-cervitem-detail-height;
  }

  .prod-item-img-carousel {
    height: 100%;
    .carousel-inner {
      height: 100%;
    }
  }

  .prod-item {
    height: 100%;
    div & div {
      background-color: #fff;
    }
  }

  .prod-by-btn-table-container {
    .prod-quantity-container {
      display: flex;
      width: 100px;
      border: 1px solid #ccc;
      input {
        width: 100%;
        text-align: center;
        font-size: 15px;
        border: 0;
        border-right: 1px solid #ccc;
      }
      button {
        border: 0;
        background-color: #fff;
      }
      .prod-quantity-plus {
      }
      .prod-quantity-minus {
        border-right: 1px solid #ccc;
      }
      .prod-quantity-btn-container {
      }
    }
  }

  .prod-by-btn-container {
    display: flex;
    justify-content: space-around;
  }

  .prod-totalprice {
    font-size: 20px;
    font-weight: 500;
    text-align: right;
    margin-right: 10px;
    color: #ae0000;
  }

  .prod-detail-img-container {
    .prod-detail-img {
      padding: 10px;
      width: 100%;
      object-fit: contain;
    }
  }
}
