@import '~styles/var';

.DateTimePicker {
  display: block;

  .rw-popup-transition,
  .rw-calendar-transition {
    transition: none;
  }

  .rw-widget-container {
    @include border-radius($input-border-radius-sm);
    border: $input-border-width solid $input-border-color;
    @include transition($input-transition);
  }
  .rw-widget-picker {
    height: $input-height-sm;
    width: calc(6.5rem + 1.9rem + 1px);

    input {
      //      height: $input-height-sm - 2px;
      display: inline-block;
      padding: $input-padding-y-sm $input-padding-x-sm;
      @include font-size($input-font-size-sm);
      width: 6.5rem !important;
      color: $input-color;
      box-shadow: none;
    }

    //아이콘
    .rw-btn {
      vertical-align: 0.2em !important;
    }
  }

  &.rw-state-focus {
    .rw-widget-picker.rw-widget-container {
      border-color: $input-focus-border-color;
      @if $enable-shadows {
        box-shadow: $input-box-shadow, $input-focus-box-shadow;
      } @else {
        box-shadow: $input-focus-box-shadow;
      }
    }
  }

  &.field-datetime .rw-widget-picker {
    width: calc(9.5rem + 1.9rem + 1px);
    input {
      width: 9.5rem !important;
    }
  }

  //요일
  .rw-calendar-grid.rw-calendar-month {
    tr > *:first-child {
      color: $danger;
    }
    tr > *:last-child {
      color: $primary;
    }
  }
}
