@import '~styles/var';

$smsArea-width: 1100px;

.SmsArea {
  width: $smsArea-width;
  transition: right $nav-transition-time, box-shadow $nav-transition-time;
  top: $header-height;
  right: 0;
  overflow-y: auto;
  position: fixed;
  height: calc(100vh - #{$header-height});
  z-index: 10;
  background-color: $background-color;
  //padding-top: 10px;
  //border-left: 1px solid $card-border-color;
  box-shadow: -3px 0 5px -1px rgba($black, 0.3);

  .card-toolbar {
    div {
      display: inline;
      margin-right: 0px;

      button {
        margin-left: 5px;
      }
    }
  }

  .container-fluid {
    padding-top: 10px;
  }

  .tip {
    transition: right $nav-transition-time, opacity $nav-transition-time;
    position: fixed;
    top: calc(#{$header-height} + 10px);
    right: $smsArea-width;
    z-index: 11;
    background-color: $pink;
    opacity: 0.8;
    color: $white;
    padding: 3px;
    font-size: 1.8rem;
    line-height: 3rem;
    vertical-align: middle;
    //border-width: 1px 0 1px 1px;
    //border-color: $card-border-color;
    //border-style: solid;
    text-align: center;
    width: 3rem;
    height: 3rem;

    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
    cursor: pointer;

    .badge {
      position: absolute;
      right: 1px;
      font-size: 10px;
      //opacity: 0.8;
      //background-color:
      background-color: rgba($warning, 0.6);
    }

    svg {
      vertical-align: initial !important;
    }

    &:hover {
      opacity: 1;
    }
  }

  .ag-cell.message {
    white-space: normal;
    line-height: 1.4em;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .ag-cell.invalid {
    background-color: rgba($danger, 0.5);
  }

  .ag-theme-balham .ag-cell.ag-cell-inline-editing {
    height: 100%;

    div {
      height: 100%;
    }
  }

  .actionBtns {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &.hide {
    right: -$smsArea-width;
    box-shadow: none;

    .tip {
      right: 0px;
    }
  }

  .left-pane {
    padding-left: 5px;
    padding-right: 2.5px;

    .container-fluid {
      padding-left: 5px;
      padding-right: 2.5px;

      form {
        .form-group {
          flex: 0 0 auto;
          width: auto;
          padding-left: 0;
          padding-right: 0;
          margin-right: 1rem;
          display: flex;
          align-items: center;

          label {
            min-width: 90px;
            text-align: right;
            margin: 0 2rem;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .right-pane {
    padding-left: 2.5px;
    padding-right: 5px;

    .container-fluid {
      padding-left: 2.5px;
      padding-right: 5px;
    }
  }
}
@include media-breakpoint-down(lg) {
  .SmsArea {
    //display: none !important;
    width: calc(100vw - 30rem);

    .tip {
      right: calc(100vw - 30rem);
    }
  }
}

@include media-breakpoint-down(md) {
  .SmsArea {
    .left-pane {
      padding-left: 5px;
      padding-right: 5px;

      .container-fluid {
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    .right-pane {
      padding-left: 5px;
      padding-right: 5px;

      .container-fluid {
        padding-left: 5px;
        padding-right: 5px;

        & > * {
          margin-bottom: 0px;
        }
      }
    }
  }
}
