@charset "UTF-8";
/*bootstrap*/
/*변수*/
/*z-index*/
:root {
  --scrollbar-width: calc(100vw - 100%); }

.DataGrid {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%; }
  .DataGrid .area-paging {
    flex: none;
    display: flex;
    height: 32px;
    background-color: #f0f3f5;
    border-color: #adb5bd;
    border-style: solid;
    border-width: 1px 0 0 0;
    align-items: center;
    padding: 0 10px;
    color: #495057;
    font-weight: bold;
    /*     & > * {
      //margin-right: 5px !important;
      &:last-child {
        margin-right: 0 !important;
      }
    } */ }
    .DataGrid .area-paging button {
      background-color: transparent;
      overflow: hidden;
      appearance: none;
      border: 0;
      padding: 0;
      width: 20px;
      height: 20px;
      color: #495057;
      cursor: pointer; }
      .DataGrid .area-paging button:disabled {
        color: #ced4da; }
    .DataGrid .area-paging .badge {
      vertical-align: 0.05em; }
  .DataGrid .ag-theme-balham {
    height: 100%;
    overflow: hidden; }
    .DataGrid .ag-theme-balham .ag-root {
      border-width: 0; }
    .DataGrid .ag-theme-balham .ag-root,
    .DataGrid .ag-theme-balham .ag-floating-top {
      border-color: #adb5bd; }
    .DataGrid .ag-theme-balham .ag-header {
      background-color: #f0f3f5; }
    .DataGrid .ag-theme-balham .ag-row {
      border-color: #ced4da; }
    .DataGrid .ag-theme-balham .ag-header-cell,
    .DataGrid .ag-theme-balham .ag-cell {
      padding-left: 6px;
      padding-right: 6px; }
    .DataGrid .ag-theme-balham .ag-cell.ag-cell-inline-editing {
      padding: 0; }
    .DataGrid .ag-theme-balham .ag-cell.button .ag-react-container {
      display: flex;
      align-items: center;
      height: 100%;
      justify-content: center; }
      .DataGrid .ag-theme-balham .ag-cell.button .ag-react-container .btn {
        height: 20px;
        line-height: 0.5; }
  .DataGrid.border-grid .ag-root {
    border-width: 1px; }
  .DataGrid.border-grid .area-paging {
    border-width: 0 1px 1px 1px; }
