@import '~styles/var';

.SmsArea {
  .noti-covid-box {
    display: none;
  }
  &.COVID {
    .send-pane,
    .message-pane {
      display: none;
    }
    .noti-covid-box {
      display: block;
    }
  }
}
