@charset "UTF-8";
/*bootstrap*/
/*변수*/
/*z-index*/
:root {
  --scrollbar-width: calc(100vw - 100%); }

.CerviAiResultWindow {
  position: fixed;
  margin: 0 !important;
  padding: 0 !important;
  z-index: 900;
  transition: left 0.3s;
  top: 3.5rem;
  left: 11rem;
  right: 0;
  bottom: 0;
  background-color: #fff;
  display: flex; }
  .CerviAiResultWindow .area-back-button {
    position: absolute;
    left: 5px;
    top: 5px; }
  @media (max-width: 575.98px) {
    .CerviAiResultWindow {
      left: 0 !important;
      top: 0 !important; } }
  .CerviAiResultWindow .aaaaaa {
    flex: 1 1;
    flex-wrap: wrap;
    align-content: flex-start; }
  .CerviAiResultWindow .highlight {
    padding: 2px 5px;
    color: white;
    background-color: #f86c6b; }
  .CerviAiResultWindow .area-back-button {
    z-index: 960; }
  .CerviAiResultWindow .picture-container {
    display: flex;
    flex-direction: column;
    flex: 1 1; }
  .CerviAiResultWindow .area-picture {
    flex: 5 1 auto;
    position: relative;
    cursor: pointer;
    background-color: #212529; }
    .CerviAiResultWindow .area-picture .inner-picture {
      pointer-events: none; }
  .CerviAiResultWindow .area-info {
    background-color: #343a40;
    flex: 0 0 400px;
    overflow-y: auto; }
    .CerviAiResultWindow .area-info > * {
      padding: 5px !important; }
    @media (max-width: 768px) {
      .CerviAiResultWindow .area-info {
        flex: 0 0 45% !important; } }
  .CerviAiResultWindow .area-picture-list {
    margin: 0;
    padding: 10px;
    display: flex;
    align-content: flex-start;
    justify-content: center;
    background-color: #212529;
    flex: 1 1; }
    .CerviAiResultWindow .area-picture-list .picture {
      margin: 0 10px;
      display: flex;
      justify-content: center; }
  .CerviAiResultWindow .classResult-grid {
    text-align: center;
    font-size: 20px; }
    .CerviAiResultWindow .classResult-grid .bg-danger-half {
      border-color: transparent !important;
      background-image: linear-gradient(90deg, #f86c6b 50%, #f8f9fa 0) !important;
      background-origin: border-box !important; }
    .CerviAiResultWindow .classResult-grid .bg-normal {
      border-color: transparent !important;
      background-image: linear-gradient(90deg, #20a8d8 25%, #f8f9fa 0) !important;
      background-origin: border-box !important; }
    .CerviAiResultWindow .classResult-grid .bg-atypical {
      border-color: transparent !important;
      background-image: linear-gradient(90deg, #f8f9fa 25%, #4dbd74 25% 50%, #f8f9fa 0) !important;
      background-origin: border-box !important; }
    .CerviAiResultWindow .classResult-grid .bg-positive1 {
      border-color: transparent !important;
      background-image: linear-gradient(90deg, #f8f9fa 50%, #ff7f50 50% 75%, #f8f9fa 0) !important;
      background-origin: border-box !important; }
    .CerviAiResultWindow .classResult-grid .bg-positive2 {
      border-color: transparent !important;
      background-image: linear-gradient(90deg, #f8f9fa 75%, #e74c3d 0) !important;
      background-origin: border-box !important; }
  .CerviAiResultWindow .classResult-TD {
    text-align: center;
    color: #343a40;
    margin: 20px 0 20px 0; }
  .CerviAiResultWindow .graph-container {
    color: white;
    margin: 5px 0 5px 0; }
  .CerviAiResultWindow .descr-container .airesultBox {
    position: absolute;
    top: -25px;
    left: 30%;
    width: 40%; }
    .CerviAiResultWindow .descr-container .airesultBox h3 {
      font-weight: 600;
      text-align: center;
      padding: 5px;
      background-color: #f8f9fa;
      border: 5px solid #ced4da !important; }
  @media (max-width: 767.98px) {
    .CerviAiResultWindow {
      flex-direction: column; }
      .CerviAiResultWindow .area-picture {
        order: 2; }
      .CerviAiResultWindow .area-info {
        order: 1;
        flex: none;
        height: inherit; } }
