.custom-popover {
  min-width: 350px;

  .text-unknown {
    color: #82878d;
  }

  .result-box {
    height: 100%;
    border: 1px solid #82878d;
    text-align: center;
    background-color: white;
    color: white;

    &.unknown {
      color: #82878d;
    }

    .true {
      border-left: 1px solid #82878d;
      background-color: green;
    }
    .false {
      border-right: 1px solid #82878d;
      background-color: red;
    }
  }
}
