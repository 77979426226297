.CervitemOrderConfirmModal {
  .search {
    .form-group {
      width: 100% !important;

      label {
        min-width: 50px !important;
      }

      .contact {
        width: 20%;
      }

      .address-container {
        width: 100%;

        .address {
          background-color: white !important;
          width: 40%;
          margin-right: 5px;
        }
      }

      .address-detail {
        width: 45%;
      }
    }
  }
}
