@charset "UTF-8";
/*bootstrap*/
/*변수*/
/*z-index*/
:root {
  --scrollbar-width: calc(100vw - 100%); }

.Video .card-body {
  min-height: 200px !important; }

.Video div.none {
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #adb5bd; }

.Video ul {
  list-style: none;
  padding-inline-start: 0;
  margin-bottom: 0; }
  .Video ul li {
    padding: 10px;
    border-bottom: 1px solid #dee2e6; }
    .Video ul li:last-child {
      border-bottom: 0px; }
    .Video ul li .row {
      cursor: pointer; }
      .Video ul li .row .title {
        font-size: 1.6em;
        font-weight: bold; }
        .Video ul li .row .title.read .unread {
          display: none; }
      .Video ul li .row .synopsis span {
        display: block; }
        .Video ul li .row .synopsis span.summary {
          font-size: 1.3em; }
      .Video ul li .row .thumbnail {
        align-items: center; }
        .Video ul li .row .thumbnail img {
          width: 100%; }
        .Video ul li .row .thumbnail button {
          margin-top: 10px;
          width: 100%; }

.PopupModal.video .modal-title {
  font-weight: bold; }

.video-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
  .video-container::after {
    padding-top: 56.25%;
    display: block;
    content: ''; }
  .video-container iframe,
  .video-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .video-container img.thumb {
    transition: transform 0.1s; }
    .video-container img.thumb:hover {
      transform: scale(1.05); }

.modal-dialog.PopupModal.video {
  max-width: 660px;
  margin: 1.75rem auto; }
