@import '~styles/var';

.DoctorEditWindow {
  .doctor-edit-content,
  .doctor-edit-validmsg {
    white-space: break-spaces;
    word-break: keep-all;
  }

  .programs {
    td {
      border: 1px solid #dadfe7;
      vertical-align: middle;
      padding: 10px;
    }
    img {
      width: 48px;
    }
  }
}
