@import '~styles/var';
@import '~styles/mixin';

.ProductWindow {
  @include window-layout;

  background-color: $gray-100;

  .area {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;

    @include media-breakpoint-down(xs) {
      .btn-popup {
        display: none;
      }
    }
  }
}
