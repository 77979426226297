@import '~styles/var';

.LanguageModal {
  .modal-header {
    padding: 1rem 2rem;

    .modal-title {
      font-size: 25px;
    }

    .close {
      font-size: 35px;
    }
  }

  .modal-body {
    padding: 2rem 2rem;

    select {
      height: 40px;
      font-size: large;
    }
  }
}
