/*bootstrap*/
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$blue: #20a8d8;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #f86c6b;
$orange: #f8cb00;
$yellow: #ffc107;
$green: #4dbd74;
$teal: #20c997;
$cyan: #17a2b8;
$coral: #ff7f50;

// Cervitem navigation bar
$nav-bar-start: #002f6c;
$nav-bar-end: #00b388;
$nav-border-start: #e87722;
$nav-border-end: #f1b434;
$nav-font: rgb(0, 10, 61);
$nav-first: #0e3260;
$nav-second: #32507a;
$nav-third: #596980;
$nav-fourth: #6a85a6;
$darkgray: #5e5e5e;
$skyBlue: #277eb7;
$sunsetOrange: #f39c11;
$crimsonRed: #e74c3d;
$tealGreen: #15a085;

$card-border-color: $gray-500; //#c8ced3;
$card-cap-bg: $gray-200;

$grid-gutter-width: 10px;

$font-size-sm: 1rem;

$input-border-color: $gray-500;

$theme-colors: (
  'pink': $pink,
  'gray': $input-border-color
);

/*변수*/
$background-color: #e4e5e6;
$nav-background-color: #2f353a;
$header-border-color: $gray-500;

$pagination-shadow: rgba(113, 113, 113, 0.3);

$mask-color: rgba($gray-700, 0.3);

$header-height: 3.5rem;
$nav-width: 11rem;
$nav-width-hide: 4rem;
$nav-transition-time: 0.3s;

//의뢰 화면 사진
$picture-preview-width: 160px;
$picture-preview-height: $picture-preview-width / 3 * 2;

//의뢰 화면 사진 (mobile)
$picture-preview-small-width: 140px;
$picture-preview-small-height: $picture-preview-small-width / 3 * 2;

//의뢰 화면 사진 (안드로이드 앱)
$picture-preview-android-width: 350px;
$picture-preview-android-height: $picture-preview-android-width / 3 * 2;

//AI분석 화면 사진
$picture-preview-big-width: 320px;
$picture-preview-big-height: $picture-preview-big-width / 3 * 2;

//서비박스 사진
$picture-preview-large-width: 180px;
$picture-preview-large-height: $picture-preview-large-width / 3 * 2;

//결과 윈도우 사진 미리보기
$picture-result-width: 120px;
$picture-result-height: $picture-result-width / 3 * 2;

//Cervitem 상세페이지 사진
$picture-cervitem-detail-width: 560px;
$picture-cervitem-detail-height: 560px;

//Cervitem 썸네일 사진
$picture-cervitem-thumb-width: 320px;
$picture-cervitem-thumb-height: 320px;

/*z-index*/
$z-index-window-layer: 900;
$z-index-progress-layer: 950;
// $nav-width: 200px;

// Cervitem navigation bar
$nav-bar-height: 70px;

:root {
  --scrollbar-width: calc(100vw - 100%);
}

@import 'node_modules/bootstrap/scss/_functions.scss';
@import 'node_modules/bootstrap/scss/_variables.scss';
@import 'node_modules/bootstrap/scss/_mixins.scss';
