@import '~styles/var';

.PictureBox {
  width: 100%;
  height: 100%;
  background-color: $gray-900;
  position: relative;
  min-height: inherit;

  & > .inner-picture {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &.contain {
      background-size: contain;
    }
  }

  & > .inner-progress {
    display: inline-block;
    width: 64px;
    height: 64px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -32px;
    margin-top: -32px;

    &.small {
      transform: scale(0.5);
    }
  }
}
