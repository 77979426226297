@import '~styles/var';

nav {
  background: $nav-background-color;

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    white-space: nowrap;
    list-style: none;

    a {
      display: block;
      color: white;

      text-decoration: none !important;
      padding: 0.5em 1em;

      &.active {
        background: #3a4248;
        color: $pink;
      }

      &:hover {
        background: $teal;
        color: white !important;
      }

      span {
        color: white;
        transition: opacity $nav-transition-time;
      }
    }
  }
}

.App.hide {
  nav li span {
    opacity: 0;
  }
}
