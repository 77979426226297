@import '~styles/var';

.RequestConfirm {
  .modal-content {
    @media (min-width: 800px) {
      transform: scale(1.2) translateY(60px);
    }
  }
  .modal-header {
    background-color: ghostwhite;
    .modal-title {
      font-weight: 600;
    }
  }

  .patinfo {
    ul {
      padding: 5px 0 5px 15px;
      margin: 0;
      list-style: none;
      li {
        margin-bottom: 5px;
      }
    }
  }
  .thumb-list {
    display: flex;
    flex-direction: column;

    ul {
      display: flex;
      flex-wrap: wrap;
      overflow-y: auto;
      padding: 5px 0 0 5px;
      list-style: none;
      margin: 0;

      li {
        margin: 0 5px 5px 0;
        border: 1px solid $gray-300;
        padding-bottom: 20px;
        .picture {
          width: $picture-preview-large-width + 40px;
          height: $picture-preview-large-height + 40px;
        }
        div {
          text-indent: 10px;
        }
      }
    }
  }
  .alert {
    padding: 5px 0 5px 15px;
    margin-bottom: 10px;
    p {
      margin: 10px 0 10px 0;
    }
    span {
      margin: 10px 0 10px 0;
    }
  }
}
