@import '~styles/var';

.CerviBoxArea {
  display: flex;
  height: 100%;

  .left-area {
    @media (max-width: 768px) {
      margin-bottom: 10px;
    }
  }

  .ResultGridCard {
    .card-footer {
      button {
        margin: 0 5px 0 0;
      }
    }
  }

  .ResultPreviewCard {
    flex: 0 0 auto;

    .card-header {
      align-items: center;
    }

    .result-preview-body-area {
      height: 250px;
      display: flex;
      flex-direction: column;

      .ofy-auto {
        overflow-y: auto;

        .ThumbListPanel {
          display: flex;
          flex-grow: 1;
          margin-bottom: 5px;

          .thumb-list {
            padding: 0;

            ul {
              overflow-y: auto;
              padding: 0;
              list-style: none;
              margin: 0;
            }

            & > ul > li {
              div.reg-date {
                position: sticky;
                top: -1px;
                z-index: 10;
                box-shadow: 0 2px 2px 0px rgba($gray-500, 0.5);
              }

              & > div {
                display: block;
                padding: 5px;
                border-width: 1px 0;
                border-style: solid;
                border-color: $gray-500;
                background: $gray-200;
              }

              &:first-child > div {
                border-top: none;
              }

              ul {
                padding: 5px 0 0 5px;

                li {
                  flex: none;
                  margin: 0 5px 5px 0;
                  border: 1px solid $gray-300;
                  background-color: $gray-100;

                  .picture-box {
                    padding: 5px;
                  }

                  &.checked {
                    border-color: $primary;
                    background-color: rgba($primary, 0.5);
                  }

                  .picture {
                    position: relative;
                    width: $picture-preview-width;
                    height: $picture-preview-height;
                    margin-right: 5px;
                    cursor: pointer;

                    button {
                      position: absolute;
                      top: 5px;
                      right: 5px;
                    }
                  }

                  .memo-area {
                    textarea {
                      margin-right: 5px;
                    }

                    button {
                      width: 75px;
                    }
                  }
                }
              }
            }

            .thumb-info {
              flex-grow: 1;

              table {
                background-color: white;

                @media (min-width: 1400px) {
                  margin-bottom: 10px;
                }
                @media (max-width: 1399px) {
                  margin-bottom: 5px;
                }

                td {
                  padding: 3px;
                  vertical-align: middle;
                }

                th {
                  width: 100px;
                  font-weight: bold;
                  background-color: $gray-200;
                  text-align: right;
                  vertical-align: middle;
                }

                td:nth-child(3) {
                  width: 100px;
                }
              }

              & > div {
                padding: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              & > div.btn-groups {
                justify-content: left;
              }
            }
          }
        }
        .ResultInfoPanel {
          height: auto;

          .patInfo {
            margin-bottom: 5px;

            table {
              background-color: white;
              border-collapse: collapse;

              @media (min-width: 1400px) {
                margin-bottom: 10px;
              }
              @media (max-width: 1399px) {
                margin-bottom: 5px;
              }
            }

            td {
              padding: 3px;
            }

            th {
              background-color: $gray-200;
              width: 100px;
              text-align: right;
              font-weight: bold;
              vertical-align: middle;
            }

            & > div {
              padding: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            td {
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
}
